import fetchCountries from '@/api/v1/countries';

/**
 * @ngDoc controller
 * @name generalMeetingMainModule.GeneralDetailsController
 * @description This controller is used to view and edit details of general meeting in Agent and Issuer workstations
 * @requires generalMeetingService,MyAccountsService are the service which is being used to interact with REST
 * @requires GeneralMeetingProgressService is used for progress chart
 * @requires $scope,$stateParams for storing/passing information in scope/state
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 */
angular
  .module('generalMeetingMainModule')
  .controller('GeneralDetailsController', [
    '$scope',
    '$stateParams',
    '$sessionStorage',
    '$log',
    '$state',
    '$filter',
    'generalMeetingService',
    'GeneralMeetingProgressService',
    'MyAccountsService',
    'GeneralMeetingDataLanguageService',
    '$timeout',
    'voteCollectorService',
    '$http',
    function (
      $scope,
      $stateParams,
      $sessionStorage,
      $log,
      $state,
      $filter,
      generalMeetingService,
      GeneralMeetingProgressService,
      MyAccountsService,
      GeneralMeetingDataLanguageService,
      $timeout,
      voteCollectorService,
      $http
    ) {
      $scope.issuerId = $stateParams.issuerId;
      $scope.meetingId = $stateParams.meetingId;
      $scope.flag = $stateParams.flag;
      $sessionStorage.status = $stateParams.status;
      $scope.status = $sessionStorage.status;
      $scope.userType = $sessionStorage.usertype;
      $scope.detailsLoading = true;
      $scope.sendRegViEuroclear = $stateParams.sendRegViEuroclear;
      $scope.languageCode = $scope.languageCode ? $scope.languageCode : 'EN';
      $scope.formLanguage = $sessionStorage.workstationData.selectedLang;
      $sessionStorage.gmLang = $scope.formLanguage;
      $scope.sendEmailEuroclear = false;
      $scope.disableApproveReject = false;
      $scope.gmGeneralDetails = false;
      $scope.issuerOrAgentSelected = false;
      $scope.shareClassList = [];
      $scope.voteCollectorListCount = 0;
      $scope.otherLanguagesGm = false;
      $scope.rejectGeneralMeeting = false;
      $scope.documentsEN = [];
      if ($sessionStorage.currentTab === 'sendEmailEuroclear') {
        $scope.sendEmailEuroclear = true;
        $state.go('generalDetailsView.sendEmailEuroclear');
      }
      if (
        $stateParams.gmDateTime === null ||
        $stateParams.gmDateTime === undefined
      ) {
        $scope.displayVoteConfirmTab = false;
      } else {
        $scope.gmDateTime = $stateParams.gmDateTime;
        $scope.gmDateTimeMilli = $stateParams.gmDateTimeMilli;
        let todaysDate = new Date();
        let todaysMillis = todaysDate.valueOf();

        if ($scope.gmDateTimeMilli < todaysMillis) {
          $scope.displayVoteConfirmTab = true;
        } else {
          $scope.displayVoteConfirmTab = false;
        }
      }
      /*to check the status for drop down in VC tab*/
      $scope.showDropDown = false;
      if ($scope.status === 'M') {
        $scope.showDropDown = true;
      } else {
        $scope.showDropDown = false;
      }
      /*to check the status for drop down in VC tab*/
      $scope.getProgressClass = function (isPastDue, index) {
        return GeneralMeetingProgressService.getProgressClass(isPastDue, index);
      };
      /*To check display of edit button*/
      $scope.genDetailsShowEdit = function () {
        $scope.showEditButton = false;
      };
      // Below is the function  used to fetch issuer logo
      $scope.getIssuerLogo = function (issuerId) {
        MyAccountsService.getIssuerGroupDetails(
          issuerId,
          $scope.languageCode
        ).then(
          function (response) {
            $scope.issuerLogoPresent = false;
            $scope.issuerGroupDetails = response.data;
            if ($scope.issuerGroupDetails.logoBaseStr) {
              $scope.issuerLogoPresent = true;
            }
          },
          function (error) {
            $log.debug(error);
          }
        );
      };

      (async () => {
        const [
          enCountries,
          nlCountries,
          frCountries,
          deCountries,
          esCountries,
        ] = await Promise.all([
          fetchCountries($http, 'EN'),
          fetchCountries($http, 'NL'),
          fetchCountries($http, 'FR'),
          fetchCountries($http, 'DE'),
          fetchCountries($http, 'ES'),
        ]);
        $scope.countryListByLanguage = {
          EN: enCountries.countries,
          NL: nlCountries.countries,
          FR: frCountries.countries,
          DE: deCountries.countries,
          ES: esCountries.countries,
        };
      })();

      // Below function is to process data to be displayed on screen for the meetings in view mode.
      $scope.processData = function (data) {
        $log.debug('isnide query');
        $scope.hideDocAccordion = true;
        $scope.languageSpecificDoc = false;
        $scope.gmGeneralDetails = true;
        $scope.generalMeetingView = data;
        $scope.tempDocVoList = data.gmDocumentVO;
        $scope.generalMeetingStatus = $scope.generalMeetingView.status;
        $scope.generalMeetingId = $scope.generalMeetingView.generalMeetingId;
        $scope.agendaItems = $scope.generalMeetingView.gmAgendaVO;
        $scope.voteCollector = $scope.generalMeetingView.gmShareClassVO;
        if ($scope.userType === 'VC') {
          generalMeetingService
          .getAssignedGeneralMeetingShareClasses($scope.meetingId)
          .query()
          .$promise.then(
              (response) => {
                processShareClassInformation(response);
              },
              (error) => $log.debug(error)
          );
        } else {
          generalMeetingService
          .getGeneralMeetingShareClasses($scope.meetingId)
          .query()
          .$promise.then(
              (response) => {
                processShareClassInformation(response);
              },
              (error) => $log.debug(error)
          );
        }

        function processShareClassInformation(response) {
          const meetingShareclasses = response.meetingShareclasses;
          $scope.voteCollector.forEach((shareClass, index) => {
            const shareClassData = meetingShareclasses.find(
                (sc) => sc.isin === shareClass.isin
            );
            if (!shareClassData) {
              $log.debug(
                  'Shareclass data for ' +
                  shareClass.isin +
                  ' not found in meetingShareclasses.'
              );
              return;
            }
            shareClass.totalEntitleVote =
                shareClassData.totalSharesEntitledToVote;
            shareClass.totalVotingRight =
                shareClassData.totalOutstandingSharesCsd;
            shareClass.voteRightPerShare =
                shareClassData.votingRightsPerShare;
            shareClass.regVoteCollectorWrkst =
                shareClassData.registrationListAvailableForVoteCollector;
            const votingThroughProxy = shareClassData.participationMethods
            .filter((pm) => pm.participationMethod === 'PRXY')
            .map((pm) => pm.canBeRequestedBy);
            shareClass.votingThroughProxyIntermediary =
                votingThroughProxy.indexOf('IN') > -1;
            shareClass.votingThroughProxyShareholder =
                votingThroughProxy.indexOf('SH') > -1;
            shareClass.votingThroughProxyShareplanholder =
                votingThroughProxy.indexOf('SP') > -1;
            const votingByCorrespondence = shareClassData.participationMethods
            .filter((pm) => pm.participationMethod === 'EVOT')
            .map((pm) => pm.canBeRequestedBy);
            shareClass.votingByCorrespondenceIntermediary =
                votingByCorrespondence.indexOf('IN') > -1;
            shareClass.votingByCorrespondenceShareholder =
                votingByCorrespondence.indexOf('SH') > -1;
            shareClass.votingByCorrespondenceShareplanholder =
                votingByCorrespondence.indexOf('SP') > -1;
            const requestAttendenceCard = shareClassData.participationMethods
            .filter((pm) => pm.participationMethod === 'PHYS')
            .map((pm) => pm.canBeRequestedBy);
            shareClass.requestAttendenceCardIntermediary =
                requestAttendenceCard.indexOf('IN') > -1;
            shareClass.requestAttendenceCardShareholder =
                requestAttendenceCard.indexOf('SH') > -1;
            shareClass.requestAttendenceCardShareplanholder =
                requestAttendenceCard.indexOf('SP') > -1;
            const virtualParticipation = shareClassData.participationMethods
            .filter((pm) => pm.participationMethod === 'VIRT')
            .map((pm) => pm.canBeRequestedBy);
            shareClass.virtualParticipationIntermediary =
                virtualParticipation.indexOf('IN') > -1;
            shareClass.virtualParticipationShareholder =
                virtualParticipation.indexOf('SH') > -1;
            shareClass.virtualParticipationShareplanholder =
                virtualParticipation.indexOf('SP') > -1;
          });
        }


        //Removed code for displaying send mail to euro clear tab
        // SFCBP-1758 - Fix start
        const gmCountryCode =
            $scope.generalMeetingView.gmDetailVOList[0].country;
        if ($scope.countryListByLanguage) {
          $scope.countryName = $scope.countryListByLanguage[
              $scope.formLanguage
              ].find((c) => gmCountryCode === c.isoCountryCode)?.countryName;
        }


        // SFCBP-1758 - Fix end
        if ($scope.voteCollector.length > 0) {
          angular.forEach($scope.voteCollector, function (value) {
            if (value.agentItself || value.issuerItself) {
              $scope.issuerOrAgentSelected = true;
            }
          });
          $scope.voteCollectorList =
              $scope.generalMeetingView.gmShareClassVO[0].authorizedVoteCollectorList;
          angular.forEach($scope.voteCollectorList, function (value, key) {
            if (!$scope.voteCollectorList[key].isDeleted) {
              $scope.voteCollectorListCount++;
            }
          });
        }
        // to display only documents where web page is selected as true
        if ($scope.tempDocVoList.length > 0) {
          $scope.generalMeetingView.gmDocumentVO = [];
          var initialiseENDoc = true;
          //displayWebEnabled - is used to check whether the document available in selected language and is allowed to display in web pages
          var displayWebEnabled = true;
          angular.forEach($scope.tempDocVoList, function (value) {
            if (
                !value.attachGmWebPage &&
                value.meetingDocAttachList.length > 0 &&
                value.meetingDocAttachList[0].logicalFileName &&
                value.meetingDocAttachList[0].languageCode === $scope.formLanguage
            ) {
              displayWebEnabled = false;
            }
            if (
                value.attachGmWebPage &&
                value.meetingDocAttachList.length > 0 &&
                value.meetingDocAttachList[0].logicalFileName
            ) {
              //Below changes are done to show documents in English if the documents are not available in other languages
              if (
                  value.meetingDocAttachList[0].languageCode === 'EN' &&
                  initialiseENDoc
              ) {
                $scope.documentsEN = [];
              }
              angular.forEach(value.meetingDocAttachList, function (value1) {
                if (value1.languageCode === $scope.formLanguage) {
                  displayWebEnabled = true;
                  $scope.generalMeetingView.gmDocumentVO.push(value);
                  $scope.languageSpecificDoc = true;
                  $scope.hideDocAccordion = false;
                }
                if (value1.languageCode === 'EN') {
                  initialiseENDoc = false;
                  $scope.documentsEN = $scope.documentsEN.concat(value);
                }
              });
            }
          });
          //Below changes are done to show documents in English if the documents are not available in other languages
          if (
              $scope.generalMeetingView.gmDocumentVO.length <= 0 &&
              $scope.documentsEN <= 0 &&
              displayWebEnabled
          ) {
            var data1 = $scope.masterDataCopy;
            data1 = GeneralMeetingDataLanguageService.getLanguageSpecificDataShareHolder(
                angular.copy(data1),
                'EN'
            );
            $scope.tempDocVoList = data1.gmDocumentVO;
            angular.forEach($scope.tempDocVoList, function (value) {
              if (
                  value.attachGmWebPage &&
                  value.meetingDocAttachList.length > 0 &&
                  value.meetingDocAttachList[0].logicalFileName
              ) {
                //Below changes are done to show documents in English if the documents are not available in other languages
                if (
                    value.meetingDocAttachList[0].languageCode === 'EN' &&
                    initialiseENDoc
                ) {
                  $scope.documentsEN = [];
                }
                angular.forEach(value.meetingDocAttachList, function (value1) {
                  $scope.generalMeetingView.gmDocumentVO.push(value);
                  $scope.languageSpecificDoc = true;
                  $scope.hideDocAccordion = false;
                  if (value1.languageCode === 'EN') {
                    initialiseENDoc = false;
                    $scope.documentsEN = $scope.documentsEN.concat(value);
                  }
                });
              }
              $log.debug($scope.documentsEN);
            });
          } else if (!$scope.languageSpecificDoc && displayWebEnabled) {
            $scope.generalMeetingView.gmDocumentVO = $scope.generalMeetingView.gmDocumentVO.concat(
                $scope.documentsEN
            );
          }
        }
        $scope.gmFeatures = $scope.generalMeetingView.gmFeaturesVO;
        $scope.issuerId = $scope.generalMeetingView.issuerId;
        $scope.gmFeaturesLength = Object.keys($scope.gmFeatures).length;
        $scope.getIssuerLogo($scope.issuerId);
        $scope.timeZone = $filter('filter')(
            $scope.timeZoneList,
            {
              timezoneID: $scope.generalMeetingView.gmScheduleVO.timezone,
            },
            true
        );
        voteCollectorService.getVoteCollectorAssignment($scope.generalMeetingId)
          .then(
            function (response) {
              $scope.agentVC = false;
              $scope.issuerVC = false;
              $scope.shareClass = response.data;
              if ($scope.shareClass.length > 0) {
                $scope.agentVC = ($scope.userType === 'AG');
                $scope.issuerVC = ($scope.userType === 'IS');
              }
            }
        );
        angular.forEach($scope.voteCollector, function (value, key) {
          if ($scope.agentVC || $scope.issuerVC) {
            angular.forEach(
                $scope.voteCollector[key].shareClassNameVoList,
                function (value, key1) {
                  if (
                      $scope.voteCollector[key].authorizedVoteCollectorList[
                          key1
                          ] !== undefined &&
                      $scope.voteCollector[key].authorizedVoteCollectorList[
                          key1
                          ] !== null
                  ) {
                    $scope.shareClassList.push({
                      shareClassName:
                      $scope.voteCollector[key].shareClassNameVoList[key1]
                          .shareClassName,
                      shareClassId: $scope.voteCollector[key].shareClassId,
                      agentVC: $scope.agentVC,
                      issuerVC: $scope.issuerVC,
                      isDeleted: false,
                      regVoteCollectorWrkst:
                      $scope.voteCollector[key].regVoteCollectorWrkst,
                      isShareplanShareClass:
                          $scope.voteCollector[key]
                              .requestAttendanceCardShareplanholder ||
                          $scope.voteCollector[key]
                              .votingInstructionShareplanholder,
                    });
                  }
                }
            );
          } else {
            angular.forEach(
                $scope.voteCollector[key].shareClassNameVoList,
                function (value, key1) {
                  if (
                      $scope.voteCollector[key].authorizedVoteCollectorList[
                          key1
                          ] !== undefined &&
                      $scope.voteCollector[key].authorizedVoteCollectorList[
                          key1
                          ] !== null
                  ) {
                    $scope.shareClassList.push({
                      shareClassName:
                      $scope.voteCollector[key].shareClassNameVoList[key1]
                          .shareClassName,
                      shareClassId: $scope.voteCollector[key].shareClassId,
                      regVoteCollectorWrkst:
                      $scope.voteCollector[key].regVoteCollectorWrkst,
                      isShareplanShareClass:
                          $scope.voteCollector[key]
                              .requestAttendanceCardShareplanholder ||
                          $scope.voteCollector[key]
                              .votingInstructionShareplanholder,
                    });
                  }
                }
            );
          }
          $log.debug('$scope.shareClassList', $scope.shareClassList);
          $sessionStorage.gmShareclassList = $scope.shareClassList;
        });
        //SFCBP-2887 Below code is moved from the beginning of the file to here,
        //as there is dependency on shereclass list stored in session storage
        if ($sessionStorage.currentTab === 'registrations') {
          $state.go('generalDetailsView.registrations');
          $timeout(function () {
            $scope.detailsLoading = false;
          }, 2000);
        } else if (
            $sessionStorage.currentTab === appConstant.VOTECOLLECTOR_TAB
        ) {
          $state.go('generalDetailsView.voteCollector');
        }
        generalMeetingService
        .getCurrentCETTime()
        .query()
        .$promise.then(
            function (data) {
              var currentDate = '';
              if (
                  $scope.generalMeetingView.gmScheduleVO.timezoneStr === 'CET'
              ) {
                currentDate = $filter('filter')(
                    data,
                    { timezone: 'CET' },
                    true
                );
                currentDate = currentDate[0].currentMillis;
              } else if (
                  $scope.generalMeetingView.gmScheduleVO.timezoneStr === 'GMT'
              ) {
                currentDate = $filter('filter')(
                    data,
                    { timezone: 'GMT' },
                    true
                );
                currentDate = currentDate[0].currentMillis;
              }
              $scope.gmProgressData = GeneralMeetingProgressService.getGeneralMeetingProgressData(
                  currentDate,
                  $scope.generalMeetingView.gmScheduleVO
              );
            },
            function (error) {
              $log.debug(error);
            }
        );
        // IF condition Added for preventing
        // redirection from Home page Action
        if (
            'generalDetailsView' === $state.current.name &&
            $sessionStorage.currentTab !== 'registrations'
        ) {
          $state.go('generalDetailsView.generalDetails');
        }
        if ($scope.userType === 'VC') {
          var gmData = {
            meetingId: $scope.meetingId,
            shareClassList: $scope.shareClassList,
            displayVoteConfirmTab: $scope.displayVoteConfirmTab,
            issuerId: $scope.issuerId,
          };
          $state.go('vcDetails.vc', {
            data: gmData,
            selectedIndex: 0,
          });
        } else {
          // IF condition Added for preventing
          // redirection from Home page Action
          if (
              'generalDetailsView' === $state.current.name &&
              $sessionStorage.currentTab !== 'registrations'
          ) {
            $state.go('generalDetailsView.generalDetails', {});
          }
        }
      };

      generalMeetingService
      .getGeneralMeetingView($scope.meetingId)
      .query()
      .$promise.then(
          function (data) {
            $scope.masterDataCopy = data;
            data = GeneralMeetingDataLanguageService.getLanguageSpecificData(
                angular.copy(data),
                $scope.formLanguage
            );
            $scope.availableLangs = data.supportedLanguages;
            $scope.processData(data);
            //SFCBP-2887 - below if condition is added to continue the loading of spinner
            //for registration tab navigation from home page
            if ($sessionStorage.currentTab !== 'registrations') {
              $scope.detailsLoading = false;
            }
          },
          function (error) {
            $scope.detailsLoading = false;
            $log.debug(error);
          }
      );
      // Below is the function  call to download Agenda documents in agenda section
      $scope.downloadAgendaDocument = function (agendaId) {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.downloadAgendaDoc =
              window.location.origin +
              '/' +
              pathList[1] +
              '/v1/generalmeetingdetails/agendas/' +
              agendaId +
              '/' +
              $scope.languageCode +
              '?authid=' +
              $sessionStorage.userId;
        } else {
          $scope.downloadAgendaDoc =
              window.location.origin +
              '/v1/generalmeetingdetails/agendas/' +
              agendaId +
              '/' +
              $scope.languageCode +
              '?authid=' +
              $sessionStorage.userId;
        }
      };
      // Below function is to find supported languages for the meetings in view mode.
      $scope.filterAvailableLangs = function (lang) {
        if ($scope.availableLangs) {
          var langPresent = $filter('filter')($scope.availableLangs, {
            languageCode: lang,
          });
          if (langPresent.length > 0 && langPresent[0].isChecked) {
            if (
                lang === 'NL' ||
                lang === 'FR' ||
                lang === 'DE' ||
                lang === 'ES'
            ) {
              $scope.otherLanguagesGm = true;
            }
            return true;
          }
        }
      };
      //Below function is to set confirmation window messages
      $scope.getActionGM = function (action) {
        if (action === 'R') {
          $scope.rejectGeneralMeeting = true;
          $scope.modalBody = $filter('translate')('areSureReject_modal');
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_cancel');
        } else {
          $scope.rejectGeneralMeeting = false;
          $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
          if ($scope.otherLanguagesGm) {
            $scope.modalBody = $filter('translate')(
                'confirmApprove_GmOtherLang'
            );
          } else {
            $scope.modalBody = $filter('translate')('confirmApprove_Gm');
          }
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_goBack');
        }
      };
      // Below function is to change languages for the meetings in view mode.
      $scope.switchFormLanguage = function (lang) {
        $scope.shareClassList = [];
        $scope.formLanguage = lang;
        $scope.$root.$broadcast('onLanguageChange', lang);
      };
      // Below function is to switch data based on selected languages for the meetings in view mode.
      $scope.$on('onLanguageChange', function (event, lang) {
        $scope.shareClassList = [];
        $scope.formLanguage = lang;
        $scope.languageCode = lang;
        $sessionStorage.gmLang = lang;
        var data = angular.copy($scope.masterDataCopy);
        data = GeneralMeetingDataLanguageService.getLanguageSpecificData(
            data,
            $scope.formLanguage
        );
        // SFCBP-1758 - Fix end
        $scope.processData(data);
      });

      // Below is the function  call to download Meeting documents in Document section
      $scope.downloadGMDocument = function (docId) {
        var pathList = window.location.pathname.split('/');
        if (pathList[1] === 'cbp') {
          $scope.downloadGmDoc =
            window.location.origin +
            '/' +
            pathList[1] +
            '/v1/generalmeetingdetails/documents/' +
            docId +
            '/' +
            $scope.languageCode +
            '?authid=' +
            $sessionStorage.userId;
        } else {
          $scope.downloadGmDoc =
            window.location.origin +
            '/v1/generalmeetingdetails/documents/' +
            docId +
            '/' +
            $scope.languageCode +
            '?authid=' +
            $sessionStorage.userId;
        }
        $log.debug($scope.downloadGmDoc);
      };
      // Below function is used to Approve or Reject General meeting from issuer work station
      $scope.approveRejectIssuerGm = function (actionStatus) {
        $scope.approveRejectGmData = {
          userType: $scope.userType,
          loggedInUserID: $sessionStorage.userId,
          generalMeetingId: $scope.meetingId,
          status: actionStatus,
        };
        generalMeetingService
          .sendToIssuerApprove(
            $scope.meetingId,
            $scope.approveRejectGmData,
            'secureToken'
          )
          .then(
            function (response) {
              $log.debug(response);
              if (actionStatus === 'R') {
                $scope.approveIssuerGMsuccess = $filter('translate')(
                  'reject_meeting'
                );
                $scope.hideEditButton = 'hide';
                $timeout(function () {
                  $state.go('generalMeetingHome');
                }, 8000);
              } else {
                $scope.approveIssuerGMsuccess = $filter('translate')(
                  'approve_meeting'
                );
              }
              $scope.disableApproveReject = true;
            },
            function (error) {
              $log.debug(error);
              if (actionStatus === 'R') {
                $scope.approveIssuerGMerror = $filter('translate')(
                  'reject_meetingErrorr'
                );
              } else {
                $scope.approveIssuerGMerror = $filter('translate')(
                  'approve_meetingErrorr'
                );
              }
              $scope.approveIssuerGMsuccess = '';
            }
          );
      };
      // Below is the method to hide or show accordian
      $scope.showHideDocs = function (agenda) {
        agenda.showDetails = !agenda.showDetails;
      };
      // Below is the function to navigate back
      $scope.goBack = function () {
        if ($scope.flag && $scope.userType !== 'IS') {
          $state.go('actionHome');
        } else if ($scope.flag && $scope.userType === 'IS') {
          //Navigation to issuer Home page
          $state.go('issuerActionHome');
        } else if ($scope.userType === 'AG') {
          //This if condition is added for redirecting to new page which is implemented
          //for agent WS as part of SFCBP-2721
          $state.go('agentGeneralMeetingHome');
        } else {
          $state.go('generalMeetingHome');
        }
      };
    },
  ]);
