import {
  getSectionalErrors,
  hasPreSubmitValidationErrors,
} from './preSubmitValidationUtil.js';
import {
  getFormValidationErrorMessages,
  createDateErrorVO,
  getNewGMData,
  getNewDynamicAdditionalDocuments,
  getNewDynamicAgendaItems,
  validateDate,
  getCreateScopeData,
  getEmptyGmScheduleVO,
  getNewAdditionalShareClasses,
  getSupportedLanguageArray,
  ensureVoteCollectorListRemains,
  prePopulateDate,
  prepareAuthorizedVoteCollectors, reorderAgendaItems, addNewDocument,

} from '@/shared/utilities/general-meeting-utilities';
import {
  prepareParticipationMethods,
  translateParticipationMethods, validateParticipationMethodForSHandSP
} from '@/shared/utilities/gm-participation-methods-util';

/**
 * @ngdoc CreateMeetingController
 * @description This controller is used for create meeting module. This mainly deals with creating and updating meeting details.
 * @requires generalMeetingService,SaveGmService,tabDataChangeService, $scope,$log,$sessionStorage,$stateParams
 * @requires userGroupService
 */
angular
  .module('generalMeetingMainModule')
  .controller('CreateMeetingController', [
    '$scope',
    '$sessionStorage',
    '$timeout',
    'generalMeetingService',
    '$stateParams',
    '$state',
    '$filter',
    '$log',
    'SaveGmService',
    'tabDataChangeService',
    'GeneralMeetingDataLanguageService',
    'userGroupService',
    'virtualParticipationService',
    'issuerPreferencesService',
    'csdService',
    '$document',
    'gmAgendaFormDataService',
    'gmDocumentFormDataService',
    'gmShareClassFormDataService',
    'gmScheduleFormDataService',
    'gmFeaturesFormDataService',
    function (
      $scope,
      $sessionStorage,
      $timeout,
      generalMeetingService,
      $stateParams,
      $state,
      $filter,
      $log,
      SaveGmService,
      tabDataChangeService,
      GeneralMeetingDataLanguageService,
      userGroupService,
      virtualParticipationService,
      issuerPreferencesService,
      csdService,
      $document,
      gmAgendaFormDataService,
      gmDocumentFormDataService,
      gmShareClassFormDataService,
      gmScheduleFormDataService,
      gmFeaturesFormDataService,
    ) {
      var gm = this;
      $scope.hstep = 1;
      $scope.mstep = 1;
      $scope.statusCalculated = 'D';
      $scope.authorizedVcSelectedError = false;
      $scope.disableSaveForValidation = false;
      $scope.options = { hstep: [1, 2, 3], mstep: [1, 5, 10, 15, 25, 30] };
      $scope.ismeridian = false;
      $scope.featureFlag = true;
      $scope.callSendToIssuer = false;
      $scope.userType = $sessionStorage.usertype;
      $scope.formLanguage = 'EN';
      $scope.tempFormLanguage = 'EN';
      $scope.englishEnabled = true;
      $scope.dutchEnabled = false;
      $scope.frenchEnabled = false;
      $scope.germanEnabled = false;
      $scope.spanishEnabled = false;
      $scope.selectedEnglish = true;
      $scope.selectedDutch = false;
      $scope.selectedGerman = false;
      $scope.selectedFrench = false;
      $scope.selectedSpanish = false;
      $scope.validateTl = false;
      $scope.validateSc = false;
      $scope.validateSh = false;
      $scope.validateAg = false;
      $scope.validateDoc = false;
      $scope.enclass = 'fontbold textUnderline';
      $scope.languageCode = 'EN';
      $scope.validationErrorFlag = false;
      $scope.gmStatus = $stateParams.gmStatus;
      gm.showIssuerAndApproveBtns = true;
      gm.disabledIssuerDropList = false;
      $scope.createGMLoading = true;
      gm.addAgendaExplaination = false;
      $scope.disableSendToIssuer = true;
      $scope.disableApproveMeeting = true;
      $scope.disableIssuerApproveMeeting = false;
      $scope.ctrlVirtualMeetingAllowed = false;
      $scope.ctrlDisableVirtualMeetingAllowed = false;
      $scope.virtualParticipationSelectedinShareClass = false;
      gm.typeAndLocAddedFlag = false;
      gm.scheduleAddedFlag = false;
      gm.shareClassAddedFlag = false;
      gm.agendaAddedFlag = false;
      gm.featuresAddedFlag = false;
      gm.documentAddedFlag = false;
      gm.meetingSavedflag = false;
      $scope.iSvCSelected = false;
      $scope.selectedintermediary = {};
      gm.sendToIssuerFieldsRequired = false;
      gm.meetingSavedflag = false;
      gm.showIssuerAprvPending = false;
      gm.virtualMeetingId = null;
      $scope.loggedInUserID = $sessionStorage.userId;
      gm.validationMessages = getFormValidationErrorMessages();
      $scope.isMeetingCreated = false;
      gm.prevTabId = '0';
      gm.currentTabId = '1';
      gm.fromJumpTab = '1';
      $scope.choosedVcL = [];
      $scope.agentIssuerUsers = [];
      $scope.shareClassWar = false;
      $scope.agendaWar = false;
      $scope.documentsWar = false;
      $scope.scheduleWar = false;
      $scope.virtualParticipationMeetingIdMissing = false;

      $scope.defaultGeneralMeetingDate = new Date().setHours(12, 0, 0, 0);
      $scope.defaultPublicationDate = new Date().setHours(8, 0, 0, 0);
      $scope.defaultRecordDate = new Date().setHours(18, 0, 0, 0);
      $scope.defaultShareholderDeadlineDate = new Date().setHours(17, 30, 0, 0);
      $scope.defaultIntermediaryDeadlineDate = new Date().setHours(11, 0, 0, 0);
      $scope.defaultRegistrationReleaseDate = new Date().setHours(13, 0, 0, 0);

      $scope.fileSizeViolations = [];

      virtualParticipationService
        .getVirtualParticipationProviders($scope.userType)
        .query()
        .$promise.then(function (response) {
          gm.virtualMeetingProviders = response;
        });

      $scope.dateErrorVO = createDateErrorVO();
      // Below is the function used to fetch issuer logo
      $scope.getIssuerLogo = function (issuerId) {
        userGroupService.getIssuerGroupLogo(issuerId).then(
          function (response) {
            $scope.issuerGroupDetails = response.data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      };
      $scope.enableDisableVirtualParticipationButtons = function(){
        // If Virtual Participation is selected in ShareClass section
        // then enable Virtual participation checkbox in Features tab
        // else block is to set default/initial values
        if ($scope.virtualParticipationSelectedinShareClass) {
          $scope.ctrlVirtualMeetingAllowed = true;
          if (gm.virtualMeetingId === null) {
            $scope.virtualParticipationMeetingIdMissing = true;
          } else {
            $scope.virtualParticipationMeetingIdMissing = false;
          }
        } else {
          $scope.ctrlVirtualMeetingAllowed = false;
          $scope.virtualParticipationMeetingIdMissing = false;
        }
        $scope.ctrlDisableVirtualMeetingAllowed = false;
      };

      gm.createData = getNewGMData();
      gm.DynamicAgendaItems = getNewDynamicAgendaItems(
        $scope.formLanguage
      );
      gm.masterAgendaDetails = angular.copy(gm.DynamicAgendaItems);
      gm.additionalShareClasses = getNewAdditionalShareClasses(
        $scope.formLanguage
      );
      gm.masterAdditionalShareClasses = angular.copy(gm.additionalShareClasses);
      gm.DynamicAdditionalDocuments = getNewDynamicAdditionalDocuments(
        $scope.formLanguage
      );
      gm.masterAdditionalDocuments = angular.copy(
        gm.DynamicAdditionalDocuments
      );
      if ($stateParams.meetingStatus === null) {
        $scope.meetingNameBrdcrmb = true;
        generalMeetingService
          .getCreateGMDropDown()
          .query()
          .$promise.then(
            function (response) {
              $scope.issuerDropDown = response.issuerVoList;
              $scope.intermediaryDropDown = response.intermediaryList;
              $scope.voteCollectorList = response.voteCollectorList;
              $sessionStorage.vColList = response.voteCollectorList;
              // SFCBP-1578 - Fix start
              $scope.countryListMaster = response.countryCodeVOList;
              $scope.countryList = GeneralMeetingDataLanguageService.getLangSpecificCountries(
                response.countryCodeVOList,
                $scope.formLanguage
              );
              // SFCBP-1578 - Fix end
              $scope.timeZoneList = response.timezoneVOList;
              $scope.meetingTypeList = response.meetingTypeCodeVOList;
              $scope.selectedTimezone = $filter('filter')($scope.timeZoneList, {
                timezoneID: 425,
              });
              $scope.timezone = '425';
              $scope.country = 'NLD';
              $scope.selectedCountry = $filter('filter')($scope.countryList, {
                isoCountryCode: $scope.country,
              });
              $scope.createGMLoading = false;
              $log.debug('success');
              $scope.enableDisableVirtualParticipationButtons();
            },
            function (error) {
              $log.debug(error);
            }
          );
      }
      $scope.onclickFile = function (e) {
        e.value = '';
      };
      $scope.agendaFileNamedisplay = [];
      $scope.documentFileNamedisplay = [];

      $scope.onFileSelect1 = function (e, listType) {
        var filesList = e.files;
        $scope.agendaFileSizeError = [];
        $scope.documentFileSizeError = [];
        //Below variable is added to display error message only for the specific language in document section
        $scope.documentCreatedLang = [];
        var index = parseInt(jQuery(e).data('index'), 10);
        if (
          !(
            'application/pdf' === filesList[0].type ||
            'application/softgrid-pdf' === filesList[0].type
          )
        ) {
          if (listType === 'DynamicAgendaItems') {
            gm.DynamicAgendaItems[index].agendaDetailVOList[0].fileName = '';
            $scope.agendaFileSizeError[index] = $filter('translate')(
              'only_pdf_allowed'
            );
            $timeout(function () {
              $scope.$apply();
            });
            return;
          } else {
            gm.DynamicAdditionalDocuments[
              index
            ].meetingDocAttachList[0].fileName = '';
            $scope.documentFileSizeError[index] = $filter('translate')(
              'only_pdf_allowed'
            );
            $scope.documentCreatedLang[index] = $scope.formLanguage;
            $timeout(function () {
              $scope.$apply();
            });
            return;
          }
        } else {
          $log.debug('error');
        }
        if (filesList[0].size > 26214400 && listType === 'DynamicAgendaItems') {
          gm.DynamicAgendaItems[index].agendaDetailVOList[0].fileName = '';
          $scope.agendaFileSizeError[index] =
            $filter('translate')('agenda_file_sizeMsg') +
            (filesList[0].size / 1024 / 1024).toFixed(2) +
            $filter('translate')('file_sizeMsg25MB');
          $timeout(function () {
            $scope.$apply();
          });
          return;
        }
        if (
          filesList[0].size > 78643200 &&
          listType === 'DynamicAdditionalDocuments'
        ) {
          gm.DynamicAdditionalDocuments[
            index
          ].meetingDocAttachList[0].fileName = '';
          $scope.documentFileSizeError[index] =
            'The attachment is too large, the maximum size allowed is 78MB';
          $scope.documentCreatedLang[index] = $scope.formLanguage;
          $timeout(function () {
            $scope.$apply();
          });
          return;
        }
        if (listType !== 'DynamicAgendaItems' && filesList[0].size > 26214400) {
          gm.DynamicAdditionalDocuments[
            index
          ].meetingDocAttachList[0].fileName = '';
          $scope.documentFileSizeError[index] = $filter('translate')(
            'label_documentLessThan25mb'
          );
          $scope.documentCreatedLang[index] = $scope.formLanguage;
          $timeout(function () {
            $scope.$apply();
          });
          return;
        }
        var fileReaderList = [];
        fileReaderList.push(new FileReader());
        fileReaderList[0].readAsDataURL(filesList[0]);
        if (listType === 'DynamicAgendaItems') {
          $scope.agendaFileNamedisplay[index] = filesList[0].name;
          gm.DynamicAgendaItems[index].agendaDetailVOList[0].fileName =
            filesList[0].name;
        } else if (listType === 'DynamicAdditionalDocuments') {
          $scope.documentFileNamedisplay[index] = filesList[0].name;
          gm.DynamicAdditionalDocuments[
            index
          ].meetingDocAttachList[0].fileName = filesList[0].name;
          gm.DynamicAdditionalDocuments[
            index
          ].meetingDocAttachList[0].fileSize = filesList[0].size;
          if ($scope.documentsWar) {
            $scope.documentsWar = false;
            $scope.removeScreenMsges();
          }
        }
        $timeout(function () {
          $scope.$apply();
        });
        fileReaderList[0].onload = function () {
          if (listType === 'DynamicAgendaItems') {
            gm.DynamicAgendaItems[index].agendaDetailVOList[0].fileName =
              filesList[0].name;
            gm.DynamicAgendaItems[index].agendaDetailVOList[0].file =
              filesList[0];
            gm.DynamicAgendaItems[index].agendaDetailVOList[0].languageCode =
              $scope.formLanguage;
          } else if (listType === 'DynamicAdditionalDocuments') {
            gm.DynamicAdditionalDocuments[
              index
            ].meetingDocAttachList[0].fileName = filesList[0].name;
            gm.DynamicAdditionalDocuments[index].meetingDocAttachList[0].file =
              filesList[0];
            gm.DynamicAdditionalDocuments[
              index
            ].meetingDocAttachList[0].langCode = $scope.formLanguage;
            //Commented as part of Making document section as non mandatory -> scope.logicalFileName is not being set and will always be undefined.
          }
        }.bind(fileReaderList[0]);
      };
      $scope.hideTextArea = function (index) {
        gm.DynamicAgendaItems[index].textExpanded = !gm.DynamicAgendaItems[
          index
        ].textExpanded;
      };
      gm.addAgendaItems = function () {
        var indexPos = gm.DynamicAgendaItems.length - 1;
        gm.DynamicAgendaItems.push({
          agendaId: null,
          index: indexPos,
          agendaNo: indexPos,
          serialNo: '',
          isVoteable: false,
          isDeleted: false,
          textExpanded: false,
          agendaDetailVOList: [
            {
              languageCode: 'EN',
              agendaExplanation: '',
              agendaTitle: '',
              file: null,
            },
          ],
        });
        if ($scope.dutchEnabled) {
          gm.DynamicAgendaItems[
            gm.DynamicAgendaItems.length - 1
          ].agendaDetailVOList.push({
            languageCode: 'NL',
            agendaExplanation: '',
            agendaTitle: '',
            file: null,
          });
        }
        if ($scope.germanEnabled) {
          gm.DynamicAgendaItems[
            gm.DynamicAgendaItems.length - 1
          ].agendaDetailVOList.push({
            languageCode: 'DE',
            agendaExplanation: '',
            agendaTitle: '',
            file: null,
          });
        }
        if ($scope.frenchEnabled) {
          gm.DynamicAgendaItems[
            gm.DynamicAgendaItems.length - 1
          ].agendaDetailVOList.push({
            languageCode: 'FR',
            agendaExplanation: '',
            agendaTitle: '',
            file: null,
          });
        }
        if ($scope.spanishEnabled) {
          gm.DynamicAgendaItems[
            gm.DynamicAgendaItems.length - 1
          ].agendaDetailVOList.push({
            languageCode: 'ES',
            agendaExplanation: '',
            agendaTitle: '',
            file: null,
          });
        }
        var filteredArray = $filter('orderBy')(
          gm.DynamicAgendaItems[gm.DynamicAgendaItems.length - 1]
            .agendaDetailVOList,
          function (records) {
            return records.languageCode !== $scope.formLanguage;
          }
        );
        gm.DynamicAgendaItems[
          gm.DynamicAgendaItems.length - 1
        ].agendaDetailVOList = filteredArray;
      };
      gm.deleteAgendaItems = function (i) {
        $scope.deleteAgendaModalWindow = false;
        if (
          gm.createData.generalMeetingId === null ||
          gm.createData.generalMeetingId === undefined ||
          gm.createData.generalMeetingId === ''
        ) {
          gm.DynamicAgendaItems.splice(i, 1);
        } else {
          if (
            null != gm.DynamicAgendaItems[i].agendaId &&
            gm.DynamicAgendaItems[i].agendaId !== 0
          ) {
            generalMeetingService.gmDeleteAgendaService(
              gm.createData.generalMeetingId,
              gm.DynamicAgendaItems[i].agendaId,
              'secureToken',
              function (response) {
                $log.debug('response', response);
                gm.DynamicAgendaItems.splice(i, 1);
                $scope.createGMsuccess = $filter('translate')(
                  'label_agendaDeleted'
                );
              },
              function (error) {
                $log.debug(error);
              }
            );
          } else {
            gm.DynamicAgendaItems.splice(i, 1);
          }
        }
      };
      gm.addDocuments = function () {
        gm.DynamicAdditionalDocuments.push(
          addNewDocument($scope.formLanguage)
        );
        if ($scope.dutchEnabled) {
          gm.DynamicAdditionalDocuments[
            gm.DynamicAdditionalDocuments.length - 1
          ].meetingDocAttachList.push({
            langCode: 'NL',
            attachedFile: '',
            fileName: '',
            fileSize: 0,
            file: null,
            logicalFileName: '',
          });
        }
        if ($scope.germanEnabled) {
          gm.DynamicAdditionalDocuments[
            gm.DynamicAdditionalDocuments.length - 1
          ].meetingDocAttachList.push({
            langCode: 'DE',
            attachedFile: '',
            fileName: '',
            fileSize: 0,
            file: null,
            logicalFileName: '',
          });
        }
        if ($scope.frenchEnabled) {
          gm.DynamicAdditionalDocuments[
            gm.DynamicAdditionalDocuments.length - 1
          ].meetingDocAttachList.push({
            langCode: 'FR',
            attachedFile: '',
            fileName: '',
            fileSize: 0,
            file: null,
            logicalFileName: '',
          });
        }
        if ($scope.spanishEnabled) {
          gm.DynamicAdditionalDocuments[
            gm.DynamicAdditionalDocuments.length - 1
          ].meetingDocAttachList.push({
            langCode: 'ES',
            attachedFile: '',
            fileName: '',
            fileSize: 0,
            file: null,
            logicalFileName: '',
          });
        }
        var filteredArray = $filter('orderBy')(
          gm.DynamicAdditionalDocuments[
            gm.DynamicAdditionalDocuments.length - 1
          ].meetingDocAttachList,
          function (records) {
            return records.langCode !== $scope.formLanguage;
          }
        );
        gm.DynamicAdditionalDocuments[
          gm.DynamicAdditionalDocuments.length - 1
        ].meetingDocAttachList = filteredArray;
      };

      gm.deleteDocuments = function (i) {
        $scope.deleteDocumentModalWindow = false;
        //Below line is added to clear the error message
        if ($scope.documentFileSizeError) {
          $scope.documentFileSizeError[i] = '';
        }
        if (
          gm.createData.generalMeetingId === null ||
          gm.createData.generalMeetingId === undefined ||
          gm.createData.generalMeetingId === ''
        ) {
          gm.DynamicAdditionalDocuments.splice(i, 1);
        } else {
          if (null != gm.DynamicAdditionalDocuments[i].meetingDocId) {
            generalMeetingService.gmDeleteDocumentService(
              gm.createData.generalMeetingId,
              gm.DynamicAdditionalDocuments[i].meetingDocId,
              'secureToken',
              function (response) {
                $log.debug('response', response);
                gm.DynamicAdditionalDocuments.splice(i, 1);
                $scope.checkMaxFileSizeViolations();
                $scope.createGMsuccess = $filter('translate')(
                  'label_documentDeleted'
                );
              },
              function (error) {
                $log.debug(error);
              }
            );
          } else {
            gm.DynamicAdditionalDocuments.splice(i, 1);
          }
        }
      };

      gm.addSahreClasses = function () {
        gm.additionalShareClasses = gmShareClassFormDataService.addNewShareClass(
          $scope.voteCollectorList,
          gm.additionalShareClasses,
          $scope.formLanguage,
          $scope.agentIssuerUsers,
          $scope.dutchEnabled,
          $scope.frenchEnabled,
          $scope.germanEnabled,
          $scope.spanishEnabled
        );

        // Put a csdId on the newly created shareClass
        gm.additionalShareClasses[
          gm.additionalShareClasses.length - 1
        ].csdId = getPreferentialCsdId();
      };
      gm.deleteSahreClasses = function (i) {
        if (
          gm.createData.generalMeetingId === null ||
          gm.createData.generalMeetingId === undefined ||
          gm.createData.generalMeetingId === ''
        ) {
          gm.additionalShareClasses.splice(i, 1);
        } else {
          if (null != gm.additionalShareClasses[i].enrollId) {
            generalMeetingService.gmDeleteShareClassService(
              gm.createData.generalMeetingId,
              gm.additionalShareClasses[i].enrollId,
              'secureToken',
              function (response) {
                $log.debug('response', response);
                gm.additionalShareClasses.splice(i, 1);
                $scope.createGMsuccess = $filter('translate')(
                  'label_shareClassDeleted'
                );
              },
              function (error) {
                $log.debug(error);
              }
            );
          } else {
            gm.additionalShareClasses.splice(i, 1);
          }
        }
      };
      $scope.changeIssuer = function (changed) {
        $scope.issuerId = changed.issuerId;
        $scope.getIssuerLogo($scope.issuerId);
      };
      $scope.changeTimezone = function (changed) {
        $scope.timezone = changed.timezoneID;
      };
      $scope.changeMeetingType = function (changed) {
        $scope.meetingType = changed.meetingType;
      };
      $scope.changeCountry = function (changed) {
        $scope.country = changed.isoCountryCode;
      };

      /**
       * Returns the languages where the max filesize has been exceeded
       *
       * The result is a list of the languages for which the filesize
       * of the attachments exceeds the max allowed value
       */
      $scope.checkMaxFileSizeViolations = function () {
        const MAX_TOTAL_FILESIZE = 24000000; // 24 mb
        const languages = gm.getSelectedLangs();
        const sizes = languages.map((l) => ({
          languageCode: l,
          current: gm.calculateTotalFileSize(l),
          limit: MAX_TOTAL_FILESIZE,
        }));

        $scope.fileSizeViolations = sizes.filter((s) => s.current > s.limit);
      };

      gm.calculateTotalFileSize = function (language) {
        if (gm.DynamicAdditionalDocuments) {
          return gm.DynamicAdditionalDocuments.filter(
            (d) => d.attachGmNotice === true // limit only applies to GM attachments
          )
            .map((d) => d.meetingDocAttachList)
            .flat()
            .filter((a) => a.langCode === language)
            .map((a) => (a.fileSize ? a.fileSize : 0))
            .reduce((acc, size) => acc + size, 0);
        }
        return 0;
      };

      gm.changeIntermediary1 = function (changed, index) {
        if (
          gm.additionalShareClasses[index].scMeetingIntermediaryAssocVOList
            .length > 0
        ) {
          gm.additionalShareClasses[
            index
          ].scMeetingIntermediaryAssocVOList[0].interemediaryID =
            changed[0].intermediaryId;
        } else {
          gm.additionalShareClasses[
            index
          ].scMeetingIntermediaryAssocVOList.push({
            interemediaryID: changed[0].intermediaryId,
            isDeleted: false,
          });
        }
      };
      gm.unCheckIsDeleted = function (valueIsDeletedIndex) {
        for (
          var j = 0;
          j <
          gm.additionalShareClasses[valueIsDeletedIndex]
            .authorizedVoteCollectorList.length;
          j++
        ) {
          var value1 =
            gm.additionalShareClasses[valueIsDeletedIndex]
              .authorizedVoteCollectorList[j];
          value1.isDeleted = true;
        }
      };
      gm.checkIsDeleted = function (valueIsDeletedIndex) {
        gm.additionalShareClasses[
          valueIsDeletedIndex
        ].votingInstructionIntermediary =
          gm.masterAdditionalShareClasses[
            valueIsDeletedIndex
          ].votingInstructionIntermediary;
        gm.additionalShareClasses[
          valueIsDeletedIndex
        ].votingInstructionShareholder =
          gm.masterAdditionalShareClasses[
            valueIsDeletedIndex
          ].votingInstructionShareholder;
        gm.additionalShareClasses[
          valueIsDeletedIndex
        ].votingInstructionShareplanholder =
          gm.masterAdditionalShareClasses[
            valueIsDeletedIndex
          ].votingInstructionShareplanholder;
      };

      gm.attachFileToChecked = function ($event) {
        if ($scope.documentsWar && $event.target.checked) {
          $scope.documentsWar = false;
          $scope.removeScreenMsges();
        }
      };
      gm.attachGMNoticeChanged = function ($event) {
        if ($scope.documentsWar && $event.target.checked) {
          $scope.documentsWar = false;
          $scope.removeScreenMsges();
        }
        $scope.checkMaxFileSizeViolations();
      };

      $scope.removeAgenda = function (index, type) {
        if (type === 'DelAg') {
          $scope.deleteAgendaModalWindow = true;
        } else if (type === 'DelDoc') {
          $scope.deleteDocumentModalWindow = true;
        }

        $scope.deleteAgDocIndex = index;
        $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
        $scope.modalBody = $filter('translate')(
          'label_delete_meeting_doc_confirmation'
        );
        $scope.modalLeftButton = $filter('translate')('label_confirm');
        $scope.modalRightButton = $filter('translate')('label_cancel');
      };

      //Set confirmation window messages
      $scope.setConfirmationModalMessage = function () {
        $scope.modalHeader = $filter('translate')('label_modalConfirmAction');
        if (gm.createData.status === 'P' && $scope.selectedEnglish) {
          if (
            $scope.selectedDutch ||
            $scope.selectedFrench ||
            $scope.selectedGerman ||
            $scope.selectedSpanish
          ) {
            $scope.modalBody = $filter('translate')(
              'confirmApprove_GmOtherLang'
            );
          } else {
            $scope.modalBody = $filter('translate')('confirmApprove_Gm');
          }
          $scope.modalLeftButton = $filter('translate')('label_confirm');
          $scope.modalRightButton = $filter('translate')('label_goBack');
        } else if (gm.createData.status === 'D') {
          $scope.modalBody = $filter('translate')('confirmFor_sendToIssuer');
          $scope.modalLeftButton = $filter('translate')('label_send');
          $scope.modalRightButton = $filter('translate')('label_cancel');
        }
      };

      //add display name for VC groups
      $scope.addGroupDisplayName = function (
        groupId,
        groupName,
        isAgent,
        isIssuer
      ) {
        $scope.displayName = '';
        $scope.languageCode = 'EN';
        $scope.enclass = 'fontbold textUnderline';
        $scope.nlclass = 'nlclass';
        $scope.frclass = 'frclass';
        $scope.declass = 'declass';
        $scope.esclass = 'esclass';
        $scope.displayNameEn = '';
        $scope.displayNameNl = '';
        $scope.displayNameFr = '';
        $scope.displayNameDe = '';
        $scope.displayNameEs = '';
        $scope.tempDisplayNameEn = '';
        $scope.tempDisplayNameFr = '';
        $scope.tempDisplayNameNl = '';
        $scope.tempDisplayNameDe = '';
        $scope.tempDisplayNameEs = '';
        $scope.groupName = groupName;
        $scope.vcGroupId = groupId;
        $scope.validationErrorFlag = false;
        if (isAgent === true) {
          $scope.vcUserType = 'AG';
        } else if (isIssuer === true) {
          $scope.vcUserType = 'IS';
        } else {
          $scope.vcUserType = 'VC';
        }
        generalMeetingService
          .viewVcDisplayName(
            groupId,
            $scope.vcUserType,
            gm.createData.generalMeetingId
          )
          .then(
            function (response) {
              var dataList = response.data;
              angular.forEach(dataList, function (item) {
                if (item.languageCode === 'EN') {
                  $scope.displayName = item.displayName;
                  $scope.tempDisplayNameEn = item.displayName;
                  $scope.displayNameEn = 'EN:' + item.displayName;
                }
                if (item.languageCode === 'FR') {
                  $scope.tempDisplayNameFr = item.displayName;
                }
                if (item.languageCode === 'NL') {
                  $scope.tempDisplayNameNl = item.displayName;
                }
                if (item.languageCode === 'DE') {
                  $scope.tempDisplayNameDe = item.displayName;
                }
                if (item.languageCode === 'ES') {
                  $scope.tempDisplayNameEs = item.displayName;
                }
              });
            },
            function (error) {
              $log.debug(error);
            }
          );
        $scope.modalHeader = $filter('translate')('label_vote_collector_group');
        $scope.modalGroupName = $filter('translate')('label_groupName');
        $scope.modalDisplayName = $filter('translate')('label_displayName');
        $scope.modalLeftButton = $filter('translate')('label_save');
        $scope.modalRightButton = $filter('translate')('label_cancel');
        $scope.modalColonStar = $filter('translate')('label_colonStar');
        $scope.modalColon = $filter('translate')('label_colon');
        $scope.modalValidationErrorMsg = $filter('translate')(
          'label_vc_alias_name_error'
        );
      };

      $scope.setLanguage = function (languageCode) {
        $scope.languageCode = languageCode;
        $scope.displayName = '';
        $scope.validationErrorFlag = false;
        if (languageCode === 'EN') {
          $scope.enclass = 'fontbold textUnderline';
          $scope.nlclass = 'nlclass';
          $scope.frclass = 'frclass';
          $scope.declass = 'declass';
          $scope.esclass = 'esclass';
          $scope.displayName = $scope.tempDisplayNameEn;
        } else if (languageCode === 'FR') {
          $scope.enclass = 'enclass';
          $scope.nlclass = 'nlclass';
          $scope.declass = 'declass';
          $scope.esclass = 'esclass';
          $scope.frclass = 'fontbold textUnderline';
          $scope.displayName = $scope.tempDisplayNameFr;
        } else if (languageCode === 'DE') {
          $scope.enclass = 'enclass';
          $scope.nlclass = 'nlclass';
          $scope.declass = 'fontbold textUnderline';
          $scope.frclass = 'frclass';
          $scope.esclass = 'esclass';
          $scope.displayName = $scope.tempDisplayNameDe;
        } else if (languageCode === 'ES') {
          $scope.enclass = 'enclass';
          $scope.nlclass = 'nlclass';
          $scope.declass = 'declass';
          $scope.frclass = 'frclass';
          $scope.esclass = 'fontbold textUnderline';
          $scope.displayName = $scope.tempDisplayNameEs;
        } else {
          $scope.enclass = 'enclass';
          $scope.nlclass = 'fontbold textUnderline';
          $scope.frclass = 'frclass';
          $scope.declass = 'declass';
          $scope.esclass = 'esclass';
          $scope.displayName = $scope.tempDisplayNameNl;
        }
      };

      $scope.setDisplayNameDetails = function () {
        var tempDisplayName = '';
        tempDisplayName = $scope.displayName;
        $scope.validationErrorFlag = false;
        if ($scope.languageCode === 'EN') {
          $scope.displayNameEn = 'EN:' + tempDisplayName;
          $scope.tempDisplayNameEn = tempDisplayName;
        } else if ($scope.languageCode === 'NL') {
          $scope.displayNameNl = 'NL:' + tempDisplayName;
          $scope.tempDisplayNameNl = tempDisplayName;
        } else if ($scope.languageCode === 'FR') {
          $scope.displayNameFr = 'FR:' + tempDisplayName;
          $scope.tempDisplayNameFr = tempDisplayName;
        } else if ($scope.languageCode === 'DE') {
          $scope.displayNameDe = 'DE:' + tempDisplayName;
          $scope.tempDisplayNameDe = tempDisplayName;
        } else if ($scope.languageCode === 'ES') {
          $scope.displayNameEs = 'ES:' + tempDisplayName;
          $scope.tempDisplayNameEs = tempDisplayName;
        }
      };

      $scope.saveVcGroupDetails = function () {
        $scope.displayNameList = [];
        if ($scope.displayNameEn !== '' && $scope.displayNameEn !== 'EN:') {
          $scope.displayNameList.push($scope.displayNameEn);
        } else {
          $scope.validationErrorFlag = true;
        }
        if ($scope.validationErrorFlag === false) {
          if ($scope.displayNameNl !== '') {
            $scope.displayNameList.push($scope.displayNameNl);
          }
          if ($scope.displayNameFr !== '') {
            $scope.displayNameList.push($scope.displayNameFr);
          }
          if ($scope.displayNameDe !== '') {
            $scope.displayNameList.push($scope.displayNameDe);
          }
          if ($scope.displayNameEs !== '') {
            $scope.displayNameList.push($scope.displayNameEs);
          }
          $scope.persistVcDetailsVo = {
            langCodeAndDisplayName: $scope.displayNameList,
            loggedInUserID: $scope.loggedInUserID,
            meetingId: gm.createData.generalMeetingId,
            userType: $scope.vcUserType,
            userGroupId: $scope.vcGroupId,
          };
          generalMeetingService
            .persistVcDisplayName($scope.persistVcDetailsVo, 'secureToken')
            .then(
              function (response) {
                $('#successMsg').modal('show');
                $log.debug(response);
                $timeout(function () {
                  $('#successMsg').modal('hide');
                  $('.modal-backdrop').remove();
                }, 2000);
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      };
      $scope.changeIssuerIsin = function (shareClassVoList, isin, index) {
        if ('Not Listed' === isin) {
          gm.additionalShareClasses[index].newisin = '';
          gm.additionalShareClasses[index].shareClassName = '';
          gm.additionalShareClasses[index].excludeFromSeev001 = false;
          gm.shareClassReqData.gmShareClassVO[
            index
          ].shareClassNameVoList[0].shareClassName = '';
          gm.additionalShareClasses[
            index
          ].shareClassNameVoList[0].shareClassName = '';
          gm.additionalShareClasses[index].csdId = $scope.csdPreference || '';
        } else {
          gm.additionalShareClasses[index].shareClassName = '';
          let isinsFilter = $filter('filter')(shareClassVoList, { isin: isin });
          $scope.shareClassNames = isinsFilter[0].shareClassNameVoList;
          gm.additionalShareClasses[index].csdId =
            isinsFilter[0].csdId || $scope.csdPreference || '';
          if (isinsFilter[0].shareClassNameVoList[0].shareClassName) {
            gm.additionalShareClasses[index].shareClassName =
              isinsFilter[0].shareClassNameVoList[0].shareClassName;
            gm.additionalShareClasses[
              index
            ].shareClassNameVoList[0].shareClassName =
              isinsFilter[0].shareClassNameVoList[0].shareClassName;
            gm.additionalShareClasses[index].shareClassId =
              isinsFilter[0].shareClassId;
            gm.additionalShareClasses[index].excludeFromSeev001 =
              isinsFilter[0].excludeFromSeev001;

            gm.shareClassReqData.gmShareClassVO[
              index
            ].shareClassNameVoList[0].shareClassName =
              isinsFilter[0].shareClassNameVoList[0].shareClassName;
            gm.shareClassReqData.gmShareClassVO[index].shareClassId =
              isinsFilter[0].shareClassId;
          } else {
            gm.additionalShareClasses[index].shareClassName = '';
            gm.shareClassReqData.gmShareClassVO[
              index
            ].shareClassNameVoList[0].shareClassName = '';
            gm.additionalShareClasses[
              index
            ].shareClassNameVoList[0].shareClassName = '';
            gm.additionalShareClasses[index].excludeFromSeev001 = false;
          }
        }
      };
      $scope.createData = getCreateScopeData();
      if ($stateParams.meetingStatus != null) {
        $scope.meetingNameBrdcrmb = false;
        $scope.createGMLoading = true;
        $scope.isMeetingCreated = true;
        $scope.createData = getNewGMData();
        $scope.createData.gmScheduleVO = getEmptyGmScheduleVO();
        generalMeetingService
          .getCreateGMDropDown()
          .query()
          .$promise.then(
            function (response) {
              $scope.issuerDropDown = response.issuerVoList;
              $scope.intermediaryDropDown = response.intermediaryList;
              $scope.voteCollectorList = response.voteCollectorList;
              //SFCBP-1578 - Fix start
              $scope.countryListMaster = response.countryCodeVOList;
              $scope.countryList = GeneralMeetingDataLanguageService.getLangSpecificCountries(
                response.countryCodeVOList,
                $scope.formLanguage
              );
              //SFCBP-1578 - Fix End
              $scope.timeZoneList = response.timezoneVOList;
              $scope.meetingTypeList = response.meetingTypeCodeVOList;
              generalMeetingService
                .getGeneralMeetingView($state.params.meetingStatus)
                .query()
                .$promise.then(
                  function (data) {
                    GeneralMeetingDataLanguageService.setLanguage(
                      $scope.formLanguage
                    );
                    if (data.status === 'R') {
                      $scope.gmStatus = 'D';
                      gm.createData.status = 'D';
                    } else {
                      $scope.gmStatus = data.status;
                      gm.createData.status = data.status;
                    }
                    if (data.statusCalculated === 'R') {
                      $scope.statusCalculated = 'D';
                    } else {
                      $scope.statusCalculated = data.statusCalculated;
                    }
                    if (data.status === 'A') {
                      gm.showIssuerAndApproveBtns = false;
                      gm.disabledIssuerDropList = true;
                    }
                    if (data.status === 'P') {
                      gm.showIssuerAndApproveBtns = false;
                      gm.showIssuerAprvPending = true;
                    }
                    gm.typeAndLocAddedFlag = true;
                    gm.createData.generalMeetingId = data.generalMeetingId;
                    $scope.issuerId = data.issuerId;
                    /*Fetching type and location data.*/
                    // true is added to filter the exact match with issuer name - SFCBP-282(issue found while testing SFCBP-2725)
                    $scope.selectedissuerName = $filter('filter')(
                      $scope.issuerDropDown,
                      { issuerName: data.issuerName },
                      true
                    );
                    $scope.meetingType = data.meetingType.trim();
                    $scope.selectedMeetingType = $filter('filter')(
                      $scope.meetingTypeList,
                      {
                        meetingType: data.meetingType.trim(),
                      }
                    );
                    gm.createData.enableVotingResolPos =
                      data.enableVotingResolPos;
                    gm.createData.reqDisclOfBeneOwner =
                      data.reqDisclOfBeneOwner;
                    $scope.createData.postalCode = $filter('htmlDecode')(
                      data.postalCode
                    );
                    $scope.createData.meetingWebsite = $filter('htmlDecode')(
                      data.meetingWebsite
                    );
                    gm.createData.languageCode = data.languageCode;
                    gm.masterTypeAndLocationDetails = data.gmDetailVOList;
                    gm.createData.gmDetailVOList = data.gmDetailVOList;
                    $scope.englishEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                      data.supportedLanguages,
                      'EN'
                    );
                    $scope.dutchEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                      data.supportedLanguages,
                      'NL'
                    );
                    $scope.germanEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                      data.supportedLanguages,
                      'DE'
                    );
                    $scope.frenchEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                      data.supportedLanguages,
                      'FR'
                    );
                    $scope.spanishEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                      data.supportedLanguages,
                      'ES'
                    );
                    $scope.selectedEnglish = $scope.englishEnabled;
                    $scope.selectedDutch = $scope.dutchEnabled;
                    $scope.selectedGerman = $scope.germanEnabled;
                    $scope.selectedFrench = $scope.frenchEnabled;
                    $scope.selectedSpanish = $scope.spanishEnabled;
                    $scope.setConfirmationModalMessage();
                    $scope.bindTypeLocationData(data.gmDetailVOList);
                    if (
                      $scope.timezone === null ||
                      $scope.timezone === undefined ||
                      $scope.timezone === ''
                    ) {
                      $scope.timezone = '425';
                    }
                    $scope.selectedTimezone = $filter('filter')(
                      $scope.timeZoneList,
                      {
                        timezoneID: data.gmScheduleVO.timezone,
                      }
                    );
                    if ($scope.selectedTimezone[0].timezoneID) {
                      $scope.timezone = $scope.selectedTimezone[0].timezoneID;
                    }
                    if (
                      data.gmScheduleVO.convocationDate !== null &&
                      data.gmScheduleVO.convocationDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.convocationDate = gm.strToDateConvert(
                        data.gmScheduleVO.convocationDate
                      );
                    }
                    if (
                      data.gmScheduleVO.publicationDate !== null &&
                      data.gmScheduleVO.publicationDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.publicationDate = gm.strToDateConvert(
                        data.gmScheduleVO.publicationDate
                      );
                    }
                    if (
                      data.gmScheduleVO.intermediaryAccessDate !== null &&
                      data.gmScheduleVO.intermediaryAccessDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.intermediaryAccessDate = gm.strToDateConvert(
                        data.gmScheduleVO.intermediaryAccessDate
                      );
                    }
                    if (
                      data.gmScheduleVO.shareholderDeadlineDate !== null &&
                      data.gmScheduleVO.shareholderDeadlineDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.shareholderDeadlineDate = gm.strToDateConvert(
                        data.gmScheduleVO.shareholderDeadlineDate
                      );
                    }
                    if (
                      data.gmScheduleVO.recordDate !== null &&
                      data.gmScheduleVO.recordDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.recordDate = gm.strToDateConvert(
                        data.gmScheduleVO.recordDate
                      );
                    }
                    if (
                      data.gmScheduleVO.intermediaryDeadlineDate !== null &&
                      data.gmScheduleVO.intermediaryDeadlineDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.intermediaryDeadlineDate = gm.strToDateConvert(
                        data.gmScheduleVO.intermediaryDeadlineDate
                      );
                    }
                    if (
                      data.gmScheduleVO.generalMeetingDate !== null &&
                      data.gmScheduleVO.generalMeetingDate !== undefined
                    ) {
                      if (data.gmFeaturesVO.virtualMeetingAllowed === false) {
                        gm.virtualMeetingDate = gm.strToDateConvert(
                          data.gmScheduleVO.generalMeetingDate
                        );
                      }
                      $scope.createData.gmScheduleVO.generalMeetingDate = gm.strToDateConvert(
                        data.gmScheduleVO.generalMeetingDate
                      );
                      gm.scheduleAddedFlag = true;
                    }
                    if (
                      data.gmScheduleVO.registrationReleaseDate !== null &&
                      data.gmScheduleVO.registrationReleaseDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.registrationReleaseDate = gm.strToDateConvert(
                        data.gmScheduleVO.registrationReleaseDate
                      );
                    }
                    if (
                      data.gmScheduleVO.shareholderAccessDate !== null &&
                      data.gmScheduleVO.shareholderAccessDate !== undefined
                    ) {
                      $scope.createData.gmScheduleVO.shareholderAccessDate = gm.strToDateConvert(
                        data.gmScheduleVO.shareholderAccessDate
                      );
                    }

                    if (
                      data.gmShareClassVO === null ||
                      data.gmShareClassVO.length === 0
                    ) {
                      gm.additionalShareClasses = [
                        {
                          enrollId: null,
                          shareClassId: null,
                          csdId: null,
                          prefixNumber: '',
                          isin: '',
                          excludeFromSeev001: false,
                          totalEntitleVote: '',
                          totalEntitleVoteEuroclearNederland: '',
                          voteRightPerShare: 1,
                          totalVotingRight: '',
                          maxNumberOfARAllowed: '',
                          votingInstructionIntermediary: true,
                          votingInstructionShareholder: true,
                          votingInstructionShareplanholder: false,
                          votingByCorrespondenceShareholder: false,
                          votingByCorrespondenceShareplanholder: false,
                          virtualParticipationShareholder: false,
                          virtualParticipationShareplanholder: false,
                          requestAttendenceCardShareholder: true,
                          requestAttendenceCardShareplanholder: false,
                          regVoteCollectorWrkst: false,
                          scMeetingIntermediaryAssocVOList: [],
                          authorizedVoteCollectorList: [],
                          shareClassVoList: [],
                          shareClassNameVoList: [
                            {
                              shareClassName: null,
                              languageCode: $scope.formLanguage,
                            },
                          ],
                        },
                      ];
                      //Added for 10652 - Need to verify
                      gm.shareClassReqData = {
                        loggedInUserID: $scope.loggedInUserID,
                        workstation: $scope.userType,
                        status: gm.createData.status,
                        generalMeetingId: gm.createData.generalMeetingId,
                        languageCode: $scope.formLanguage,
                        issuerId: $scope.issuerId,
                        supportedLanguages: [],
                        gmShareClassVO: [],
                        shareClassVoList: [],
                      };
                      var notListedvalue = {
                        isin: 'Not Listed',
                        shareClassId: '',
                        shareClassNameVoList: [],
                      };
                      gm.shareClassReqData.shareClassVoList.push(
                        notListedvalue
                      );
                      angular.forEach(data.shareClassVoList, function (value) {
                        gm.shareClassReqData.shareClassVoList.push(value);
                      });
                      generalMeetingService
                        .getCreateGMVoteCollectors(
                          $sessionStorage.groupId,
                          $scope.issuerId
                        )
                        .query()
                        .$promise.then(
                          function (response) {
                            $scope.agentIssuerUsers =
                              response.voteCollectorList;
                            angular.forEach($scope.agentIssuerUsers, function (
                              value
                            ) {
                              gm.additionalShareClasses[0].authorizedVoteCollectorList.push(
                                value
                              );
                              gm.masterAdditionalShareClasses[0].authorizedVoteCollectorList.push(
                                value
                              );
                            });
                            angular.forEach($scope.voteCollectorList, function (
                              value
                            ) {
                              gm.additionalShareClasses[0].authorizedVoteCollectorList.push(
                                {
                                  userGroupID: value.userGroupID,
                                  vcID: value.vcID,
                                  isDeleted: true,
                                  isAgent: false,
                                  isIssuer: false,
                                  isVoteConfirmed: false,
                                  vcName: value.vcName,
                                  vcEmailID: value.vcEmailID,
                                }
                              );
                              gm.masterAdditionalShareClasses[0].authorizedVoteCollectorList.push(
                                {
                                  userGroupID: value.userGroupID,
                                  vcID: value.vcID,
                                  isDeleted: true,
                                  isAgent: false,
                                  isIssuer: false,
                                  isVoteConfirmed: false,
                                  vcName: value.vcName,
                                  vcEmailID: value.vcEmailID,
                                }
                              );
                            });
                          },
                          function (error) {
                            $log.debug(error);
                          }
                        );
                      // When there is no shareclass in the meeting, flag is set to false
                      $scope.virtualParticipationSelectedinShareClass = false;
                      $scope.ctrlDisableVirtualMeetingAllowed = false;
                    } else {
                      if (data.gmShareClassVO.length > 0) {
                        gm.shareClassAddedFlag = true;
                        $scope.selectedintermediary = [];
                        $scope.choosedCheckboxes = [];
                        for (var x = 0; x < data.gmShareClassVO.length; x++) {
                          if (
                            data.gmShareClassVO[x]
                              .scMeetingIntermediaryAssocVOList.length > 0
                          ) {
                            $scope.selectedintermediary[x] = $filter('filter')(
                              $scope.intermediaryDropDown,
                              {
                                inBinder:
                                  data.gmShareClassVO[x]
                                    .scMeetingIntermediaryAssocVOList[0]
                                    .inBinder,
                              }
                            );
                          }
                        }
                      }
                      generalMeetingService
                        .getCreateGMVoteCollectors(
                          $sessionStorage.groupId,
                          $scope.issuerId
                        )
                        .query()
                        .$promise.then(
                          function (response) {
                            $scope.agentIssuerUsers =
                              response.voteCollectorList;
                          },
                          function (error) {
                            $log.debug(error);
                          }
                        );
                      gm.masterAdditionalShareClasses = translateParticipationMethods(
                        data.gmShareClassVO
                      );
                      gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
                        angular.copy(gm.masterAdditionalShareClasses)
                      );
                      gm.checkIfVirtualParticipationIsSelectedAsParticipationMethod(gm.masterAdditionalShareClasses);
                      gm.prefillShareclassesWithCsdPreference();

                      //Added for 10652
                      gm.shareClassReqData = {
                        loggedInUserID: $scope.loggedInUserID,
                        workstation: $scope.userType,
                        status: gm.createData.status,
                        generalMeetingId: gm.createData.generalMeetingId,
                        languageCode: $scope.formLanguage,
                        issuerId: $scope.issuerId,
                        supportedLanguages: [],
                        gmShareClassVO: [],
                        shareClassVoList: [],
                      };
                      var notListedvalue = {
                        isin: 'Not Listed',
                        shareClassId: '',
                        shareClassNameVoList: [],
                      };
                      gm.shareClassReqData.shareClassVoList.push(
                        notListedvalue
                      );
                      angular.forEach(data.shareClassVoList, function (value) {
                        gm.shareClassReqData.shareClassVoList.push(value);
                      });
                      gm.shareClassReqData.shareClassVoList = GeneralMeetingDataLanguageService.getShareClassVOListCreateGM(
                        gm.shareClassReqData.shareClassVoList
                      );
                    }
                    if (
                      data.gmAgendaVO === null ||
                      data.gmAgendaVO.length === 0
                    ) {
                      gm.DynamicAgendaItems = [
                        {
                          agendaId: null,
                          index: 0,
                          agendaNo: 0,
                          serialNo: '',
                          isVoteable: false,
                          isDeleted: false,
                          textExpanded: false,
                          agendaDetailVOList: [
                            {
                              agendaExplanation: '',
                              agendaTitle: '',
                              file: null,
                              languageCode: $scope.formLanguage,
                            },
                          ],
                        },
                      ];
                    } else {
                      gm.masterAgendaDetails = data.gmAgendaVO;
                      gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
                        angular.copy(data.gmAgendaVO)
                      );
                      gm.agendaAddedFlag = true;
                    }
                    gm.splitVotingIntermdWrkst =
                      data.gmFeaturesVO.splitVotingIntermdWrkst;
                    gm.rightToSpeak = data.gmFeaturesVO.rightToSpeak;
                    gm.shWrkstSecAccDiscReq =
                      data.gmFeaturesVO.shWrkstSecAccDiscReq;
                    //Below flag and meetingid are added for lumi enhancement - SFCBP-3007
                    gm.virtualMeetingAllowed = data.gmFeaturesVO.virtualMeetingAllowed
                        ? data.gmFeaturesVO.virtualMeetingAllowed : false;
                    gm.disableVirtualMeetingAllowed =
                      data.gmFeaturesVO.disableVirtualParticipationAllowed;
                    gm.virtualMeetingId = gm.zeroPad(
                      data.gmFeaturesVO.virtualMeetingId,
                      9
                    );
                    gm.selectedVirtualMeetingProvider =
                      data.gmFeaturesVO.virtualParticipationProviderId;

                    if (data.gmFeaturesVO.virtualMeetingAllowed === true) {
                      var virtualTimeAndDate =
                        data.gmFeaturesVO
                          .virtualParticipationJoinDatetimeMillis;
                      var virtualTimeZone = data.gmScheduleVO.timezoneStr;
                      var virtualMeetingDateStr = $filter('cbpDate')(
                        virtualTimeAndDate,
                        virtualTimeZone,
                        false
                      );
                      gm.virtualMeetingDate = gm.strToDateConvert(
                        virtualMeetingDateStr
                      );
                    }
                    //Below flag is added for linked shareclass meeting changes - SFCBP-2725
                    gm.shWrkstLinkMeeting =
                      data.gmFeaturesVO.shWrkstLinkMeeting;
                    //Below line is for send report to euroclear option checkbox value
                    gm.sendRegViEuroclear =
                      data.gmFeaturesVO.sendRegViEuroclear;
                    gm.regTickBarcode = data.gmFeaturesVO.regTickBarcode;
                    gm.regTickAgentLogo = data.gmFeaturesVO.regTickAgentLogo;
                    gm.regTickIssuerLogo = data.gmFeaturesVO.regTickIssuerLogo;
                    gm.regTickIncludePOA = data.gmFeaturesVO.regTickIncludePOA;
                    gm.regTickIncludeMeetingDoc =
                      data.gmFeaturesVO.regTickIncludeMeetingDoc;
                    gm.featuresAddedFlag = true;
                    if (
                      data.gmDocumentVO === null ||
                      data.gmDocumentVO.length === 0
                    ) {
                      gm.DynamicAdditionalDocuments = [
                        {
                          meetingDocId: null,
                          attachGmNotice: false,
                          attachGmWebPage: false,
                          attachCard: false,
                          meetingDocAttachList: [
                            {
                              attachedFile: '',
                              fileName: '',
                              file: null,
                              langCode: $scope.formLanguage,
                              logicalFileName: '',
                            },
                          ],
                        },
                      ];
                    } else {
                      gm.masterAdditionalDocuments = data.gmDocumentVO;
                      gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
                        angular.copy(data.gmDocumentVO)
                      );
                      $scope.checkMaxFileSizeViolations();
                      gm.documentAddedFlag = true;
                    }
                    $scope.disableSendToIssuer = false;
                    $scope.createGMLoading = false;
                  },
                  function (error) {
                    $log.debug(error);
                  }
                );
              $log.debug('success');
            },
            function (error) {
              $log.debug(error);
            }
          );
      }
      gm.createGMTypeLocationData = function (eventFlag, lang) {
        if (gm.createData.status === null || gm.createData.status === 'D') {
          gm.createData.status = 'D';
          $scope.gmStatus = 'D';
          $scope.setConfirmationModalMessage();
        }
        if (
          gm.createData.generalMeetingId === null ||
          gm.createData.generalMeetingId === undefined ||
          gm.createData.generalMeetingId === ''
        ) {
          gm.createData.generalMeetingId = null;
        }
        gm.createData.issuerId = $scope.issuerId;
        gm.createData.meetingType = $scope.meetingType;
        gm.createData.loggedInUserID = $scope.loggedInUserID;
        gm.createData.enableVotingResolPos = false;
        gm.createData.reqDisclOfBeneOwner = false;
        if (
          $scope.createData.postalCode === '' ||
          $scope.createData.postalCode === undefined
        ) {
          $scope.createData.postalCode = null;
        }
        gm.createData.postalCode = $scope.createData.postalCode;
        gm.createData.meetingWebsite = $scope.createData.meetingWebsite;
        gm.createData.languageCode = 'EN';
        gm.createData.gmDetailVOList[0].languageCode = $scope.formLanguage;
        gm.createData.gmDetailVOList[0].locationName = $scope.locationName;
        gm.createData.gmDetailVOList[0].locationTicket = $scope.locationTicket;
        gm.createData.gmDetailVOList[0].street = $scope.street;
        gm.createData.gmDetailVOList[0].city = $scope.city;
        gm.createData.gmDetailVOList[0].country = $scope.country;
        gm.createData.gmDetailVOList[0].meetinName = $scope.meetinName;
        gm.createGMTypeLocationReqData = {
          generalMeetingId: gm.createData.generalMeetingId,
          status: gm.createData.status,
          issuerId: gm.createData.issuerId,
          meetingType: gm.createData.meetingType,
          loggedInUserID: gm.createData.loggedInUserID,
          workstation: $scope.userType,
          postalCode: gm.createData.postalCode,
          meetingWebsite: gm.createData.meetingWebsite,
          languageCode: 'EN',
          gmDetailVOList: [],
          supportedLanguages: [],
        };
        gm.createGMTypeLocationReqData.supportedLanguages = getSupportedLanguageArray(
          $scope.selectedEnglish,
          $scope.selectedDutch,
          $scope.selectedFrench,
          $scope.selectedGerman,
          $scope.selectedSpanish
        );
        gm.createGMTypeLocationReqData.gmDetailVOList = angular.copy(
          gm.createData.gmDetailVOList
        );
        SaveGmService.createGmTypeAndLocation(
          gm.createGMTypeLocationReqData,
          $scope.validateTl
        ).then(
          function (response) {
            if ($scope.validateTl) {
              $scope.confirmationBoxPostValidation();
            } else {
              $scope.createGmErrRes = '';
              if (eventFlag === 'save') {
                $scope.removeScreenMsges();
                $scope.createGMsuccess = response.data.message;
                $scope.issuerApproveError = '';
              } else if (eventFlag === 'languageChange') {
                $scope.formLanguage = lang;
              }
              $scope.isMeetingCreated = true;
              if (response.data.typeAndLocationVO.generalMeetingId !== null) {
                if (
                  gm.createData.generalMeetingId === null ||
                  gm.createData.generalMeetingId === undefined
                ) {
                  generalMeetingService
                    .getCreateGMVoteCollectors(
                      $sessionStorage.groupId,
                      gm.createData.issuerId
                    )
                    .query()
                    .$promise.then(
                      function (response) {
                        $scope.agentIssuerUsers = response.voteCollectorList;
                        angular.forEach(response.voteCollectorList, function (
                          value
                        ) {
                          gm.additionalShareClasses[0].authorizedVoteCollectorList.push(
                            value
                          );
                          gm.masterAdditionalShareClasses[0].authorizedVoteCollectorList.push(
                            value
                          );
                        });
                        angular.forEach($scope.voteCollectorList, function (
                          value
                        ) {
                          const voteCollector = {
                            userGroupID: value.userGroupID,
                            vcID: value.vcID,
                            isDeleted: true,
                            isAgent: false,
                            isIssuer: false,
                            isVoteConfirmed: false,
                            vcName: value.vcName,
                            vcEmailID: value.vcEmailID,
                          };
                          gm.additionalShareClasses[0].authorizedVoteCollectorList.push(
                            voteCollector
                          );
                          gm.masterAdditionalShareClasses[0].authorizedVoteCollectorList.push(
                            voteCollector
                          );
                        });
                        gm.shareClassReqData = {
                          loggedInUserID: $scope.loggedInUserID,
                          workstation: $scope.userType,
                          status: gm.createData.status,
                          generalMeetingId: gm.createData.generalMeetingId,
                          languageCode: $scope.formLanguage,
                          issuerId: $scope.issuerId,
                          supportedLanguages: [],
                          gmShareClassVO: [],
                          shareClassVoList: [],
                        };
                        var notListedvalue = {
                          isin: 'Not Listed',
                          shareClassId: '',
                          shareClassNameVoList: [],
                        };
                        gm.shareClassReqData.shareClassVoList.push(
                          notListedvalue
                        );
                        angular.forEach(
                          response.issuer.shareClassVOs,
                          function (value) {
                            gm.shareClassReqData.shareClassVoList.push(value);
                          }
                        );
                        gm.shareClassReqData.shareClassVoList = GeneralMeetingDataLanguageService.getShareClassVOListCreateGM(
                          gm.shareClassReqData.shareClassVoList
                        );
                      },
                      function (error) {
                        $log.debug(error);
                      }
                    );
                }
                gm.typeAndLocAddedFlag = true;
                gm.createData.generalMeetingId =
                  response.data.typeAndLocationVO.generalMeetingId;
                gm.masterTypeAndLocationDetails =
                  response.data.typeAndLocationVO.gmDetailVOList;
                gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
                  angular.copy(response.data.typeAndLocationVO.gmDetailVOList)
                );
                $scope.englishEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                  response.data.typeAndLocationVO.supportedLanguages,
                  'EN'
                );
                $scope.dutchEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                  response.data.typeAndLocationVO.supportedLanguages,
                  'NL'
                );
                $scope.germanEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                  response.data.typeAndLocationVO.supportedLanguages,
                  'DE'
                );
                $scope.frenchEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                  response.data.typeAndLocationVO.supportedLanguages,
                  'FR'
                );
                $scope.spanishEnabled = GeneralMeetingDataLanguageService.getSupportedLangTpeFlag(
                  response.data.typeAndLocationVO.supportedLanguages,
                  'ES'
                );
                $scope.selectedEnglish = $scope.englishEnabled;
                $scope.selectedDutch = $scope.dutchEnabled;
                $scope.selectedGerman = $scope.germanEnabled;
                $scope.selectedFrench = $scope.frenchEnabled;
                $scope.selectedSpanish = $scope.spanishEnabled;
                $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
              }
              if ($scope.callSendToIssuer) {
                gm.sendToIssuerPreCheck($scope.sendApproveStatus);
              } else {
                $scope.disableSaveForValidation = false;
                $scope.disableSendToIssuer = false;
              }
              gm.meetingSavedflag = true;
              $scope.createGMLoading = false;
              // Logic to save default features when GM is created.
              if ($stateParams.meetingStatus === null && $scope.featureFlag) {
                gm.createGmFeaturesData();
              }
              $scope.enableDisableVirtualParticipationButtons();
            }
          },
          function (error) {
            $scope.createGMsuccess = '';
            $scope.disableSaveForValidation = false;
            $scope.disableSendToIssuer = false;
            if ($scope.validateTl) {
              $scope.sectionalErrors = tabDataChangeService.sendToIssuerLangErrors(
                error.data.generalMeetingVO
              );
              if ($scope.sectionalErrors && $scope.sectionalErrors.length > 0) {
                $scope.sendErrorsReported = $filter('translate')(
                  'error_send_to_issuer'
                );
                angular.forEach($scope.sectionalErrors, function (errorObj) {
                  if (errorObj.tabName === 'shareClass') {
                    $scope.shareClassWar = true;
                    $scope.shareClassLang = errorObj.lang;
                  } else if (errorObj.tabName === 'agenda') {
                    $scope.agendaWar = true;
                    $scope.agendaLang = errorObj.lang;
                  } else if (errorObj.tabName === 'documents') {
                    $scope.documentsWar = true;
                    $scope.documentsLang = errorObj.lang;
                  } else if (errorObj.tabName === 'scheduleWar') {
                    $scope.scheduleWar = true;
                    $scope.scheduleLang = errorObj.lang;
                  } else if (errorObj.tabName === 'typeAndLocation') {
                    $scope.typeAndLocationWar = true;
                    $scope.typeAndLocationLang = errorObj.lang;
                  } else if (errorObj.tabName === 'virtualMeetingId') {
                    $scope.virtualParticipationMeetingIdMissing = true;
                  }
                });
              }
              $scope.disableSaveForValidation = false;
              $scope.createGMLoading = false;
            }
            if (error.data.message) {
              $scope.createGmErrRes = error.data.message;
            } else {
              $scope.createGmErrRes = $filter('translate')(
                'label_createMeetingError'
              );
            }
            $scope.createGMLoading = false;
            $scope.enableDisableVirtualParticipationButtons();
          }
        );
      };
      gm.zeroPad = function (number, length) {
        if (number === undefined) {
          return null;
        }
        var str = '' + number;
        while (str.length < length) {
          str = '0' + str;
        }
        return str;
      };
      gm.createScheduleData = function (eventFlag) {
        $scope.gmScheduleVOData = gmScheduleFormDataService.createGMScheduleData(
          gm,
          $scope.createData.gmScheduleVO,
          $scope.timezone
        );
        gm.ScheduleReqData = {
          loggedInUserID: $scope.loggedInUserID,
          workstation: $scope.userType,
          generalMeetingId: gm.createData.generalMeetingId,
          status: gm.createData.status,
          languageCode: 'EN',
          issuerId: $scope.issuerId,
          supportedLanguages: [],
          gmScheduleVO: {},
        };
        gm.ScheduleReqData.gmScheduleVO = angular.copy($scope.gmScheduleVOData);
        gm.ScheduleReqData.supportedLanguages = getSupportedLanguageArray(
          $scope.selectedEnglish,
          $scope.selectedDutch,
          $scope.selectedFrench,
          $scope.selectedGerman,
          $scope.selectedSpanish
        );
        SaveGmService.createGmScheduleTab(
          gm.createData.generalMeetingId,
          gm.ScheduleReqData,
          $scope.validateSc
        ).then(
          function (response) {
            if ($scope.validateSc) {
              $scope.confirmationBoxPostValidation();
            } else {
              $scope.createGmErrRes = '';
              if (eventFlag === 'save') {
                $scope.removeScreenMsges();
                $scope.createGMsuccess = response.data.message;
                $scope.issuerApproveError = '';
              }
              if (
                response.data.scheduleVO.gmScheduleVO.generalMeetingDate !==
                undefined
              ) {
                gm.scheduleAddedFlag = true;
              }
              $scope.isMeetingCreated = true;
              if ($scope.callSendToIssuer) {
                gm.sendToIssuerPreCheck($scope.sendApproveStatus);
              } else {
                $scope.disableSaveForValidation = false;
                $scope.disableSendToIssuer = false;
              }
              gm.meetingSavedflag = true;
              $log.debug(response);
              $scope.createGMLoading = false;
            }
            $scope.enableDisableVirtualParticipationButtons();
          },
          function (error) {
            $scope.createGMsuccess = '';
            $scope.disableSaveForValidation = false;
            $scope.disableSendToIssuer = false;
            if ($scope.validateSc) {
              $scope.sectionalErrors = tabDataChangeService.sendToIssuerLangErrors(
                error.data.generalMeetingVO
              );
              if ($scope.sectionalErrors && $scope.sectionalErrors.length > 0) {
                $scope.sendErrorsReported = $filter('translate')(
                  'error_send_to_issuer'
                );
                angular.forEach($scope.sectionalErrors, function (errorObj) {
                  if (errorObj.tabName === 'shareClass') {
                    $scope.shareClassWar = true;
                    $scope.shareClassLang = errorObj.lang;
                  } else if (errorObj.tabName === 'agenda') {
                    $scope.agendaWar = true;
                    $scope.agendaLang = errorObj.lang;
                  } else if (errorObj.tabName === 'documents') {
                    $scope.documentsWar = true;
                    $scope.documentsLang = errorObj.lang;
                  } else if (errorObj.tabName === 'scheduleWar') {
                    $scope.scheduleWar = true;
                    $scope.scheduleLang = errorObj.lang;
                  } else if (errorObj.tabName === 'typeAndLocation') {
                    $scope.typeAndLocationWar = true;
                    $scope.typeAndLocationLang = errorObj.lang;
                  } else if (errorObj.tabName === 'virtualMeetingId') {
                    $scope.virtualParticipationMeetingIdMissing = true;
                  }
                });
              }
              $scope.disableSaveForValidation = false;
              $scope.createGMLoading = false;
            }
            if (error.data.message) {
              $scope.createGmErrRes = error.data.message;
            }
            $scope.createGMLoading = false;
            $scope.enableDisableVirtualParticipationButtons();
          }
        );
      };

      gm.showErrorInShareClass = function () {
        $scope.vcGroupDisplayNameErr = true;
        $scope.createGMLoading = false;
        $scope.createGMsuccess = '';
        $scope.disableSaveForValidation = false;
        $scope.disableSendToIssuer = false;
      };
      // Check and Set virtualParticipationSelectedinShareClass flag based
      // on selection of Virtual Participation method
      gm.checkIfVirtualParticipationIsSelectedAsParticipationMethod = function (gmShareClassVO) {
        $scope.virtualParticipationSelectedinShareClass = false;
        $scope.ctrlDisableVirtualMeetingAllowed = false;
        gmShareClassVO.forEach(
            (gmShareClass) => {
              if (gmShareClass.virtualParticipationShareholder ||
                  gmShareClass.virtualParticipationShareplanholder
              ) {
                $scope.virtualParticipationSelectedinShareClass = true;
              }
            }
        );
      };
      gm.validateParticipationMethodsSelection = function (gm) {
        for (let i = 0; i < gm.shareClassReqData.gmShareClassVO.length; i++) {
          // If a shareclass is setup with EVOT option,
          // there must be an issuer votecollector selected for the shareclass
          if (gm.shareClassReqData.gmShareClassVO[i].authorizedVoteCollectorList.length
              > 0
              && (gm.shareClassReqData.gmShareClassVO[i].votingByCorrespondenceShareplanholder
                  ||
                  gm.shareClassReqData.gmShareClassVO[i].votingByCorrespondenceShareholder)) {
            const issuerVoteCollectorCount =
                gm.shareClassReqData.gmShareClassVO[i].authorizedVoteCollectorList.filter(
                (vc) => vc.isIssuer).length;
            if (issuerVoteCollectorCount < 1) {
              $scope.createGmErrRes = $filter('translate')(
                  'error_issuer_votecollector_not_selected'
              );
              $scope.isParticipationMethodSelectionValid = false;
              gm.showErrorInShareClass();
              break;
            }
          }
          if (gm.shareClassReqData.gmShareClassVO[i].requestAttendenceCardShareplanholder
              === false
              && gm.shareClassReqData.gmShareClassVO[i].requestAttendenceCardShareholder
              === false &&
              gm.shareClassReqData.gmShareClassVO[i].votingInstructionShareplanholder
              === false &&
              gm.shareClassReqData.gmShareClassVO[i].votingInstructionShareholder
              === false &&
              gm.shareClassReqData.gmShareClassVO[i].votingInstructionIntermediary
              === false &&
              gm.shareClassReqData.gmShareClassVO[i].votingByCorrespondenceShareplanholder
              === false &&
              gm.shareClassReqData.gmShareClassVO[i].votingByCorrespondenceShareholder
              === false) {
            $scope.isParticipationMethodSelectionValid = false;
            $scope.createGmErrRes = $filter('translate')(
                'share_class_error_checkbox_not_selected'
            );
            gm.showErrorInShareClass();
            break;
          } else {
            $scope.isParticipationMethodSelectionValid =
                validateParticipationMethodForSHandSP(
                    gm.shareClassReqData.gmShareClassVO[i]);
            if (!$scope.isParticipationMethodSelectionValid) {
              $scope.createGmErrRes = $filter('translate')(
                  'error_shareClass_not_created_invalid_participation_data'
              );
              gm.showErrorInShareClass();
              break;
            } else {
              $scope.isParticipationMethodSelectionValid = true;
            }
          }
        }
      };



      /**
       * Request object and service call for share class section.
       *
       * Constructs a shareClassReqData object on `gm` (which is the
       * controller), based on - primarily - gm.createData and
       * gm.additionalShareClasses.
       *
       * @param {*} eventFlag
       * @param {*} lang
       */
      gm.createShareClassData = function (eventFlag, lang) {
        $scope.vcGroupDisplayNameErr = false;
        if (eventFlag === 'save') {
          $scope.createGMLoading = true;
        }
        gm.shareClassReqData.generalMeetingId = gm.createData.generalMeetingId;
        gm.shareClassReqData.issuerId = $scope.issuerId;

        // request body to server requires participation methods to be passed in a non-boolean method
        gm.shareClassReqData.gmShareClassVO = prepareParticipationMethods(
            angular.copy(gm.additionalShareClasses)
        );
        // cleaning the authorized vote collector list to only contain the authorized vote collectors
        gm.shareClassReqData.gmShareClassVO = prepareAuthorizedVoteCollectors(
            gm.shareClassReqData.gmShareClassVO
        );

        gm.validateParticipationMethodsSelection(gm);
        if ($scope.isParticipationMethodSelectionValid) {
          gm.checkIfVirtualParticipationIsSelectedAsParticipationMethod(
              gm.shareClassReqData.gmShareClassVO);
          //Added for 10652
          gm.shareClassReqData.gmShareClassVO.forEach((gmShareClass) => {
            if (gmShareClass.isin === 'Not Listed') {
              gmShareClass.isin = gmShareClass.newisin;
              gmShareClass.enrollId = null;
              gmShareClass.shareClassId = null;
            }

            /**
             * If the shareClass is a 'shareplan' shareClass, we don't need
             * csd info
             */
            if (
                gmShareClass.requestAttendenceCardShareplanholder ||
                gmShareClass.votingInstructionShareplanholder ||
                gmShareClass.votingByCorrespondenceShareplanholder ||
                gmShareClass.virtualParticipationShareplanholder
            ) {
              /**
               * Instead of deleting the csdId from the request, we just put in an arnitrary
               * csdId - it won't be used anyway...
               *
               * // delete gmShareClass.csdId;
               */
              gmShareClass.csdId = getPreferentialCsdId();
              delete gmShareClass.csdName;
            } else {
              // A Csd _is_ required. If it's somehow not filled here when we get here,
              // simply inject a 'random' (Euroclear NL - arbitrarily) CSD
              if (!gmShareClass.csdId) {
                gmShareClass.csdId = getPreferentialCsdId();
              }
            }
          });

          SaveGmService.createGmShareClasses(
              gm.createData.generalMeetingId,
              gm.shareClassReqData,
              $scope.validateSh
          ).then(
              function (response) {
                $scope.genericErrors = [];
                $scope.createGmErrRes = '';
                if ($scope.validateSh) {
                  $scope.confirmationBoxPostValidation();
                } else {
                  $scope.createGmErrRes = '';
                  if (eventFlag === 'save') {
                    $scope.removeScreenMsges();
                    $scope.createGMLoading = false;
                    $scope.createGMsuccess = response.data.message;
                    $scope.issuerApproveError = '';
                  } else if (eventFlag === 'languageChange') {
                    $scope.formLanguage = lang;
                  }

                  $scope.isMeetingCreated = true;
                  $scope.enableDisableVirtualParticipationButtons();
                  $scope.updateVirtualParticipationAllowed();
                  if (response.data.gmShareClassVO) {
                    if (
                        response.data.gmShareClassVO !== null &&
                        response.data.gmShareClassVO.length > 0
                    ) {
                      gm.shareClassAddedFlag = true;
                      gm.masterAdditionalShareClasses = ensureVoteCollectorListRemains(
                          response.data.gmShareClassVO,
                          $scope.voteCollectorList.concat(
                              $scope.agentIssuerUsers)
                      );
                      gm.masterAdditionalShareClasses = translateParticipationMethods(
                          gm.masterAdditionalShareClasses
                      );

                      $scope.selectedintermediary = {};
                      for (
                          var i = 0;
                          i < response.data.gmShareClassVO.length;
                          ++i
                      ) {
                        $scope.selectedintermediary[i] = {};
                        if (
                            response.data.gmShareClassVO[i]
                                .scMeetingIntermediaryAssocVOList.length > 0
                        ) {
                          $scope.selectedintermediary[i][0] = $filter('filter')(
                              $scope.intermediaryDropDown,
                              {
                                inBinder:
                                response.data.gmShareClassVO[i]
                                    .scMeetingIntermediaryAssocVOList[0].inBinder,
                              }
                          )[0];
                        }
                      }
                      gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
                          angular.copy(response.data.gmShareClassVO)
                      );

                      gm.shareClassReqData.shareClassVoList = [];
                      var notListedvalue = {
                        isin: 'Not Listed',
                        shareClassId: '',
                        shareClassNameVoList: [],
                      };
                      gm.shareClassReqData.shareClassVoList.push(
                          notListedvalue);
                      angular.forEach(response.data.shareClassVoList, function (
                          value
                      ) {
                        gm.shareClassReqData.shareClassVoList.push(value);
                      });
                      gm.shareClassReqData.shareClassVoList = GeneralMeetingDataLanguageService.getShareClassVOListCreateGM(
                          gm.shareClassReqData.shareClassVoList
                      );
                    }
                  }
                  if ($scope.callSendToIssuer) {
                    gm.sendToIssuerPreCheck($scope.sendApproveStatus);
                  } else {
                    $scope.disableSaveForValidation = false;
                    $scope.disableSendToIssuer = false;
                  }
                  gm.meetingSavedflag = true;
                  $scope.createGMLoading = false;
                }
              },
              function (error) {
                $scope.createGMsuccess = '';
                $scope.disableSaveForValidation = false;
                $scope.disableSendToIssuer = false;
                $scope.vcGroupDisplayNameErr = false;
                if (
                    error.data &&
                    error.data.errors &&
                    error.data.errors.length > 0
                ) {
                  angular.forEach(error.data.gmShareClassVO,
                      function (vcError) {
                        if (
                            vcError.errors.length > 0 &&
                            vcError.errors[0].errorCode === 'vcID'
                        ) {
                          $scope.vcErr = vcError;
                          $scope.vcGroupDisplayNameErr = true;
                          $scope.vcGroupDisplayNameErrMessage =
                              vcError.errors[0].errorMessage;
                        }
                      });
                }
                if (error.status === 500 && eventFlag === 'save') {
                  if (error.data.errors[0].errorCode === 'CBP_ERR_63') {
                    $scope.createGmErrRes = $filter('translate')(
                        'error_shareClass_not_created'
                    );
                  } else {
                    $scope.createGmErrRes = error.data.errors[0].errorMessage;
                  }
                } else if (error.status === 400 && eventFlag === 'save') {
                  $scope.createGmErrRes = $filter('translate')(
                      'error_shareClass_not_created_invalid_data'
                  );
                }
                if ($scope.validateSh) {
                  $scope.shareClassWar = true;
                  $scope.sectionalErrors = tabDataChangeService.sendToIssuerLangErrors(
                      error.data.generalMeetingVO
                  );
                  if ($scope.sectionalErrors && $scope.sectionalErrors.length
                      > 0) {
                    $scope.sendErrorsReported = $filter('translate')(
                        'error_send_to_issuer'
                    );
                    angular.forEach($scope.sectionalErrors,
                        function (errorObj) {
                          if (errorObj.tabName === 'shareClass') {
                            $scope.shareClassWar = true;
                            $scope.shareClassLang = errorObj.lang;
                          } else if (errorObj.tabName === 'agenda') {
                            $scope.agendaWar = true;
                            $scope.agendaLang = errorObj.lang;
                          } else if (errorObj.tabName === 'documents') {
                            $scope.documentsWar = true;
                            $scope.documentsLang = errorObj.lang;
                          } else if (errorObj.tabName === 'scheduleWar') {
                            $scope.scheduleWar = true;
                            $scope.scheduleLang = errorObj.lang;
                          } else if (errorObj.tabName === 'typeAndLocation') {
                            $scope.typeAndLocationWar = true;
                            $scope.typeAndLocationLang = errorObj.lang;
                          } else if (errorObj.tabName === 'virtualMeetingId') {
                            $scope.virtualParticipationMeetingIdMissing = true;
                          }
                        });
                  }
                  $scope.disableSaveForValidation = false;
                  $scope.createGMLoading = false;
                }
                if (error.data.message) {
                  $scope.createGmErrRes = error.data.message;
                }
                $scope.createGMLoading = false;
              }
          );
        }
      };

      /*Request object and service call for agenda section.*/
      gm.createAgendaData = function (eventFlag, lang) {
        $scope.createGMLoading = true;
        var agendaFormData = gmAgendaFormDataService.createAgendaFormData(
          $scope.userType,
          $scope.loggedInUserID,
          $scope.formLanguage,
          gm.createData.generalMeetingId,
          gm,
          $scope.issuerId,
          $scope.selectedEnglish,
          $scope.selectedDutch,
          $scope.selectedGerman,
          $scope.selectedFrench,
          $scope.selectedSpanish
        );
        SaveGmService.createGmAgenda(
          gm.createData.generalMeetingId,
          agendaFormData,
          $scope.validateAg
        ).then(
          function (response) {
            $scope.createGMLoading = false;
            if ($scope.validateAg) {
              $scope.confirmationBoxPostValidation();
            } else {
              $scope.createGmErrRes = '';
              $scope.validateAg = false;
              if (eventFlag === 'save') {
                $scope.removeScreenMsges();
                $scope.createGMsuccess = response.data.message;
                $scope.issuerApproveError = '';
              } else if (eventFlag === 'languageChange') {
                $scope.formLanguage = lang;
              }
              $scope.isMeetingCreated = true;
              if (
                response.data.agendasVO.gmAgendaVO !== null &&
                response.data.agendasVO.gmAgendaVO.length > 0
              ) {
                gm.agendaAddedFlag = true;
                gm.masterAgendaDetails = response.data.agendasVO.gmAgendaVO;
                gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
                  gm.masterAgendaDetails
                );
              }
              gm.meetingSavedflag = true;
              if ($scope.callSendToIssuer) {
                gm.sendToIssuerPreCheck($scope.sendApproveStatus);
              } else {
                $scope.disableSaveForValidation = false;
                $scope.disableSendToIssuer = false;
              }
            }
            $scope.enableDisableVirtualParticipationButtons();
            $timeout(function () {
              $scope.$apply();
            });
          },
          function (error) {
            $scope.createGMsuccess = '';
            $scope.disableSaveForValidation = false;
            $scope.disableSendToIssuer = false;
            if (error.status === 422 || error.status === 500) {
              if (
                error.data.errors !== undefined &&
                error.data.errors[0].errorCode === 'CBP_ERR_63'
              ) {
                $scope.createGmErrRes = $filter('translate')(
                  'label_fillValidInput'
                );
              } else if (
                error.data.errors !== undefined &&
                error.data.errors[0].errorCode === 'CBP_ERR_155'
              ) {
                $scope.createGmErrRes = error.data.errors[0].errorMessage;
              }
            } else if (400 === error.status && eventFlag === 'save') {
              $scope.createGmErrRes = $filter('translate')(
                'label_fillMandatoryFields'
              );
            }
            if ($scope.validateAg) {
              $scope.sectionalErrors = [];
              $scope.createGmErrRes = error.data.message;
              $scope.agendaWar = true;
              $scope.sectionalErrors = tabDataChangeService.sendToIssuerLangErrors(
                error.data.agendasVO
              );
              if ($scope.sectionalErrors && $scope.sectionalErrors.length > 0) {
                $scope.sendErrorsReported = $filter('translate')(
                  'error_send_to_issuer'
                );
                angular.forEach($scope.sectionalErrors, function (errorObj) {
                  if (errorObj.tabName === 'shareClass') {
                    $scope.shareClassWar = true;
                    $scope.shareClassLang = errorObj.lang;
                  } else if (errorObj.tabName === 'agenda') {
                    $scope.agendaWar = true;
                    $scope.agendaLang = errorObj.lang;
                  } else if (errorObj.tabName === 'documents') {
                    $scope.documentsWar = true;
                    $scope.documentsLang = errorObj.lang;
                  } else if (errorObj.tabName === 'scheduleWar') {
                    $scope.scheduleWar = true;
                    $scope.scheduleLang = errorObj.lang;
                  } else if (errorObj.tabName === 'typeAndLocation') {
                    $scope.typeAndLocationWar = true;
                    $scope.typeAndLocationLang = errorObj.lang;
                  } else if (errorObj.tabName === 'virtualMeetingId') {
                    $scope.virtualParticipationMeetingIdMissing = true;
                  }
                });
              }
              $scope.disableSaveForValidation = false;
              $scope.createGMLoading = false;
            }
            $scope.createGMLoading = false;
            $scope.enableDisableVirtualParticipationButtons();
          }
        );
      };
      gm.createGmFeaturesData = function (eventFlag) {
        gm.createData.gmFeaturesVO = gmFeaturesFormDataService.createGMFeatureData(gm);
        // To avoid API failures for bad requests while switching between tabs
        gm.createData.gmFeaturesVO.virtualMeetingAllowed
        = $scope.virtualParticipationSelectedinShareClass;
        gm.featureReqData = {
          loggedInUserID: $scope.loggedInUserID,
          workstation: $scope.userType,
          status: gm.createData.status,
          generalMeetingId: gm.createData.generalMeetingId,
          languageCode: 'EN',
          issuerId: $scope.issuerId,
          supportedLanguages: [],
          gmFeaturesVO: {},
        };
        gm.featureReqData.gmFeaturesVO = angular.copy(
          gm.createData.gmFeaturesVO
        );
        gm.featureReqData.supportedLanguages = getSupportedLanguageArray(
          $scope.selectedEnglish,
          $scope.selectedDutch,
          $scope.selectedFrench,
          $scope.selectedGerman,
          $scope.selectedSpanish
        );
        SaveGmService.createGmFeature(
          gm.createData.generalMeetingId,
          gm.featureReqData
        ).then(
          function (response) {
            $scope.featureFlag = false;
            $scope.createGmErrRes = '';
            //Below line added for linked meeting check box should be checked - SFCBP-2725
            gm.shWrkstLinkMeeting =
              response.data.featuresVO.gmFeaturesVO.shWrkstLinkMeeting;
            if (eventFlag === 'save') {
              $scope.removeScreenMsges();
              $scope.createGMsuccess = response.data.message;
              $scope.issuerApproveError = '';
            }
            $scope.isMeetingCreated = true;
            gm.meetingSavedflag = true;
            gm.featuresAddedFlag = true;
            if ($scope.callSendToIssuer) {
              gm.sendToIssuerPreCheck($scope.sendApproveStatus);
            } else {
              $scope.disableSaveForValidation = false;
              $scope.disableSendToIssuer = false;
            }
            $log.debug(response);
            $scope.createGMLoading = false;
          },
          function (error) {
            $scope.createGMsuccess = '';
            $scope.disableSaveForValidation = false;
            $scope.disableSendToIssuer = false;
            if (error.data.message) {
              $scope.createGmErrRes = error.data.message;
            }
            $scope.createGMLoading = false;
          }
        );
      };
      gm.createGMDocumntsData = function (eventFlag, lang) {
        var documentFormData = gmDocumentFormDataService.createGMDocumentsData(
          $scope.userType,
          $scope.loggedInUserID,
          $scope.formLanguage,
          gm.createData.generalMeetingId,
          gm,
          $scope.selectedEnglish,
          $scope.selectedDutch,
          $scope.selectedFrench,
          $scope.selectedGerman,
          $scope.selectedSpanish
        );
        SaveGmService.createGmDocuments(
          gm.createData.generalMeetingId,
          documentFormData,
          $scope.validateDoc
        ).then(
          function (response) {
            if ($scope.validateDoc) {
              $scope.confirmationBoxPostValidation();
            } else {
              $scope.createGmErrRes = '';
              if (eventFlag === 'save') {
                $scope.removeScreenMsges();
                $scope.createGMsuccess = response.data.message;
                $scope.issuerApproveError = '';
              } else if (eventFlag === 'languageChange') {
                $scope.formLanguage = lang;
              }
              $scope.isMeetingCreated = true;
              if (
                response.data.documentsVO.gmDocumentVO !== null &&
                response.data.documentsVO.gmDocumentVO.length > 0
              ) {
                gm.documentAddedFlag = true;
                gm.masterAdditionalDocuments =
                  response.data.documentsVO.gmDocumentVO;
                gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
                  angular.copy(response.data.documentsVO.gmDocumentVO)
                );
                //Below line is added to clear the error message
                $scope.documentFileSizeError = [];
                $scope.checkMaxFileSizeViolations();
              }
              gm.meetingSavedflag = true;
              if ($scope.callSendToIssuer) {
                gm.sendToIssuerPreCheck($scope.sendApproveStatus);
              } else {
                $scope.disableSaveForValidation = false;
                $scope.disableSendToIssuer = false;
              }
              $scope.createGMLoading = false;
            }
            $scope.enableDisableVirtualParticipationButtons();
          },
          function (error) {
            $scope.createGMsuccess = '';
            $scope.disableSaveForValidation = false;
            $scope.disableSendToIssuer = false;
            $scope.docError = false;
            if (error.data.errors) {
              if (error.data.errors[0].errorCode === 'CBP_ERR_475') {
                $scope.createGmErrRes = $filter('translate')(
                  'label_documentLessThan25mb'
                );
              }
            }
            if (400 === error.status && eventFlag === 'save') {
              $scope.documentsWar = true;
              //Below condition is added to display error from backend - ALM-10900
              if (error.data.message) {
                $scope.createGmErrRes = error.data.message;
              } else {
                $scope.createGmErrRes = $filter('translate')(
                  'label_attachmentRequired'
                );
              }
            }
            if ($scope.validateDoc) {
              $scope.documentsWar = true;
              if (error.status === 422 && error.data.documentsVO) {
                $scope.createGmErrRes = $filter('translate')(
                  'error_send_to_issuer'
                );
              } else {
                $scope.createGmErrRes = error.data.message;
              }
              $scope.sectionalErrors = [];
              if ($scope.sectionalErrors && $scope.sectionalErrors.length > 0) {
                $scope.sendErrorsReported = error.data.message;
                angular.forEach($scope.sectionalErrors, function (errorObj) {
                  if (errorObj.tabName === 'shareClass') {
                    $scope.shareClassWar = true;
                    $scope.shareClassLang = errorObj.lang;
                  } else if (errorObj.tabName === 'agenda') {
                    $scope.agendaWar = true;
                    $scope.agendaLang = errorObj.lang;
                  } else if (errorObj.tabName === 'documents') {
                    $scope.documentsWar = true;
                    $scope.documentsLang = errorObj.lang;
                  } else if (errorObj.tabName === 'scheduleWar') {
                    $scope.scheduleWar = true;
                    $scope.scheduleLang = errorObj.lang;
                  } else if (errorObj.tabName === 'typeAndLocation') {
                    $scope.typeAndLocationWar = true;
                    $scope.typeAndLocationLang = errorObj.lang;
                  } else if (errorObj.tabName === 'virtualMeetingId') {
                    $scope.virtualParticipationMeetingIdMissing = true;
                  }
                });
              }
              $scope.disableSaveForValidation = false;
              $scope.createGMLoading = false;
            }
            $scope.createGMLoading = false;
            $scope.enableDisableVirtualParticipationButtons();
          }
        );
      };
      gm.checkIsIssuerEnabled = function () {
        if ($scope.isMeetingCreated) {
          $scope.disableSendToIssuer = false;
        }
      };

      gm.handlePreSubmitToIssuerFailures = function (
        errors,
        sendApproveStatus
      ) {
        if (sendApproveStatus === 'P' || sendApproveStatus === 'A') {
          $scope.sendErrorsReported = $filter('translate')(
            'error_send_to_issuer'
          );

          $scope.genericErrors = [];
          angular.forEach(errors['errors'], function (err) {
            $scope.genericErrors.push(
              err['errorCode'] + ': ' + err['errorMessage']
            );
          });

          [$scope.shareClassWar, $scope.shareClassLang] = getSectionalErrors(
            errors,
            'shareclassErrors'
          );

          [$scope.agendaWar, $scope.agendaLang] = getSectionalErrors(
            errors,
            'agendaErrors'
          );

          [$scope.documentsWar, $scope.documentsLang] = getSectionalErrors(
            errors,
            'documentErrors'
          );

          [$scope.scheduleWar, $scope.scheduleLang] = getSectionalErrors(
            errors,
            'scheduleErrors'
          );

          [
            $scope.typeAndLocationWar,
            $scope.typeAndLocationLang,
          ] = getSectionalErrors(errors, 'typeAndLocationErrors');

          [$scope.featuresWar, $scope.featuresLang] = getSectionalErrors(
            errors,
            'featureErrors'
          );

          $scope.sectionalErrors = [
            ...$scope.genericErrors,
            ...$scope.shareClassLang,
            ...$scope.agendaLang,
            ...$scope.documentsLang,
            ...$scope.scheduleLang,
            ...$scope.typeAndLocationLang,
            ...$scope.featuresLang,
          ]; // for backwards compatibility with the older sections of the html
        }

        $scope.disableSendToIssuer = false;
        $scope.disableSaveForValidation = false;
        $scope.createGMLoading = false;
        $timeout(function () {
          $scope.$apply();
        });
      };

      gm.handlePreSubmitToIssuerSuccess = function (sendApproveStatus) {
        $timeout(function () {
          if (
            $scope.myForm.$valid &&
            $scope.dateErrorVO.validDates &&
            !$scope.dateErrorVO.dateCheked1
          ) {
            $scope.removeScreenMsges();
            $scope.invalidFormError = '';
            if (sendApproveStatus === 'P' || sendApproveStatus === 'A') {
              $('#pop').modal('show');
              $scope.disableSendToIssuer = false;
              $scope.disableSaveForValidation = false;
            }
          } else {
            $scope.invalidFormError = $filter('translate')(
              'label_allSectionNotFilled'
            );
            $scope.disableSendToIssuer = false;
            $scope.disableSaveForValidation = false;
          }
          $scope.createGMLoading = false;
        }, 1100);
      };

      gm.sendToIssuerPreCheck = function (sendApproveStatus) {
        $scope.createGMsuccess = '';
        $scope.createGMLoading = true;
        $scope.dateErrorVO = validateDate(
          $scope.createData.gmScheduleVO,
          $scope.dateErrorVO
        );
        $scope.vcGroupDisplayNameErr = false;
        gm.sendToIssuerFieldsRequired = true;
        $scope.callSendToIssuer = false;
        $scope.dateError = $scope.dateErrorVO.validDates;
        gm.sendApproveData = {
          userType: $scope.userType,
          loggedInUserID: $scope.loggedInUserID,
          generalMeetingId: gm.createData.generalMeetingId,
          status: sendApproveStatus,
        };
        $scope.setConfirmationModalMessage();
        generalMeetingService
          .validateOnlySendToIssuerApprove(gm.createData.generalMeetingId)
          .then(
            function (response) {
              if (hasPreSubmitValidationErrors(response.data)) {
                gm.handlePreSubmitToIssuerFailures(
                  response.data,
                  sendApproveStatus
                );
              } else {
                gm.handlePreSubmitToIssuerSuccess(sendApproveStatus);
              }
            },
            function (error) {}
          );
      };

      gm.sendToIssuer = function (sendApproveStatus) {
        if (
          (sendApproveStatus === 'A' || sendApproveStatus === 'P') &&
          (!$scope.dateErrorVO.validDates || $scope.dateErrorVO.dateCheked1)
        ) {
          $scope.dateError = $scope.dateErrorVO.validDates;
          return;
        }
        if (sendApproveStatus === 'A') {
          $scope.removeScreenMsges();
          gm.sendToIssuerFieldsRequired = true;
          $scope.callSendToIssuer = false;
          gm.sendApproveData = {
            userType: $scope.userType,
            loggedInUserID: $scope.loggedInUserID,
            generalMeetingId: gm.createData.generalMeetingId,
            status: sendApproveStatus,
          };
        }
        $scope.createGMLoading = true;
        generalMeetingService
          .sendToIssuerApprove(
            gm.createData.generalMeetingId,
            gm.sendApproveData,
            'secureToken'
          )
          .then(
            function (response) {
              if (sendApproveStatus === 'P') {
                $scope.disableSendToIssuer = true;
                $scope.createGMsuccess = $filter('translate')(
                  'label_meetingSentToIssuer'
                );
                $scope.disableApproveMeeting = false;
                var toBackPage = document.getElementById('goback');
                $('#sendToIssuerSuccessPopUp').modal('show');
                $timeout(function () {
                  $('#sendToIssuerSuccessPopUp').modal('hide');
                  toBackPage.click();
                  $('.modal-backdrop').remove();
                }, 3000);
              } else if (sendApproveStatus === 'A') {
                $scope.createGMsuccess = $filter('translate')(
                  'label_meetingApproved'
                );
                $scope.issuerApproveError = '';
                $scope.disableIssuerApproveMeeting = true;
                var toBackPage = document.getElementById('goback');
                toBackPage.click();
              }
              $scope.createGMLoading = false;
            },
            function (error) {
              $scope.disableSendToIssuer = false;
              $scope.disableSaveForValidation = false;
              if (sendApproveStatus === 'P' || sendApproveStatus === 'A') {
                $scope.sectionalErrors = tabDataChangeService.sendToIssuerLangErrors(
                  error.data.generalMeetingVO
                );
                if (
                  $scope.sectionalErrors &&
                  $scope.sectionalErrors.length > 0
                ) {
                  $scope.sendErrorsReported = $filter('translate')(
                    'error_send_to_issuer'
                  );
                  angular.forEach($scope.sectionalErrors, function (errorObj) {
                    if (errorObj.tabName === 'shareClass') {
                      $scope.shareClassWar = true;
                      $scope.shareClassLang = errorObj.lang;
                    } else if (errorObj.tabName === 'agenda') {
                      $scope.agendaWar = true;
                      $scope.agendaLang = errorObj.lang;
                    } else if (errorObj.tabName === 'documents') {
                      $scope.documentsWar = true;
                      $scope.documentsLang = errorObj.lang;
                    } else if (errorObj.tabName === 'scheduleWar') {
                      $scope.scheduleWar = true;
                      $scope.scheduleLang = errorObj.lang;
                    } else if (errorObj.tabName === 'typeAndLocation') {
                      $scope.typeAndLocationWar = true;
                      $scope.typeAndLocationLang = errorObj.lang;
                    } else if (errorObj.tabName === 'virtualMeetingId') {
                      $scope.virtualParticipationMeetingIdMissing = true;
                    }
                  });
                }
              } else if (sendApproveStatus === 'A') {
                $log.debug(error);
                $scope.issuerApproveError = error.data.errors[0].errorMessage;
                $scope.createGMsuccess = '';
              }
              $scope.createGMLoading = false;
            }
          );
      };
      gm.dateToStringConverter = function (dateString) {
        var date = new Date(dateString);
        return $filter('date')(date, 'yyyy-MM-dd HH:mm:ss');
      };
      gm.strToDateConvert = function (dateStr) {
        return new Date(dateStr);
      };
      gm.showAgendaExplain = function () {
        gm.addAgendaExplaination = true;
      };
      $scope.onReorder = function (srcIndex, destIndex) {
        var agendaItemList = [];
        agendaItemList.push(gm.DynamicAgendaItems);
        agendaItemList.push(gm.masterAgendaDetails);
        reorderAgendaItems(
          agendaItemList,
          srcIndex,
          destIndex
        );
      };
      $scope.removeScreenMsges = function () {
        $scope.createGmErrRes = '';
        $scope.createGMsuccess = '';
        $scope.sendToIssuersErrors = '';
        $scope.sendErrorsReported = '';
        $scope.invalidFormError = '';
        $scope.shareClassWar = false;
        $scope.agendaWar = false;
        $scope.documentsWar = false;
        $scope.scheduleWar = false;
        $scope.typeAndLocationWar = false;
        $scope.vcGroupDisplayNameErr = false;
        $scope.enableDisableVirtualParticipationButtons();
      };
      $scope.nonDratTabChange = function (tabId) {
        switch (tabId.toString()) {
          case appConstant.GM_FIRST_TAB:
            gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
              angular.copy(gm.createData.gmDetailVOList)
            );
            $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
            break;
          case appConstant.GM_THIRD_TAB:
            gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
              angular.copy(gm.additionalShareClasses)
            );
            break;
          case appConstant.GM_FORTH_TAB:
            gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
              gm.DynamicAgendaItems
            );
            break;
          case appConstant.GM_SIXTH_TAB:
            gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
              gm.DynamicAdditionalDocuments
            );
            break;
          default:
        }
        return;
      };
      $scope.reloadData = function () {
        gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
          angular.copy(gm.masterTypeAndLocationDetails)
        );
        $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
        gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
          angular.copy(gm.masterAdditionalShareClasses)
        );
        gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
          gm.DynamicAgendaItems
        );
        gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
          gm.DynamicAdditionalDocuments
        );
      };
      $scope.saveGmSections = function (tabId) {
        if (gm.createData.status !== 'D') {
          gm.fromJumpTab = gm.currentTabId;
          GeneralMeetingDataLanguageService.setLanguage($scope.formLanguage);
          switch (gm.fromJumpTab.toString()) {
            case appConstant.GM_FIRST_TAB:
              gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
                angular.copy(gm.createData.gmDetailVOList)
              );
              $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
              $scope.nonDratTabChange(tabId);
              break;
            case appConstant.GM_SECOND_TAB:
              break;
            case appConstant.GM_THIRD_TAB:
              gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
                angular.copy(gm.additionalShareClasses)
              );
              $scope.nonDratTabChange(tabId);
              break;
            case appConstant.GM_FORTH_TAB:
              gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
                gm.DynamicAgendaItems
              );
              $scope.nonDratTabChange(tabId);
              break;
            case appConstant.GM_FIFTH_TAB:
              break;
            case appConstant.GM_SIXTH_TAB:
              gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
                gm.DynamicAdditionalDocuments
              );
              $scope.nonDratTabChange(tabId);
              break;
            default:
          }
          gm.currentTabId = tabId;
          return;
        }
        $scope.createGMLoading = true;
        gm.fromJumpTab = gm.currentTabId;
        GeneralMeetingDataLanguageService.setLanguage($scope.formLanguage);
        switch (gm.fromJumpTab.toString()) {
          case appConstant.GM_FIRST_TAB:
            gm.createGMTypeLocationData('section');
            break;
          case appConstant.GM_SECOND_TAB:
            gm.createScheduleData('section');
            break;
          case appConstant.GM_THIRD_TAB:
            gm.createShareClassData('section');
            break;
          case appConstant.GM_FORTH_TAB:
            gm.createAgendaData('section');
            break;
          case appConstant.GM_FIFTH_TAB:
            gm.createGmFeaturesData('section');
            break;
          case appConstant.GM_SIXTH_TAB:
            gm.createGMDocumntsData('section');
            break;
          default:
        }
        switch (tabId.toString()) {
          case appConstant.GM_FIRST_TAB:
            gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
              angular.copy(gm.masterTypeAndLocationDetails)
            );
            $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
            break;
          case appConstant.GM_THIRD_TAB:
            gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
              angular.copy(gm.masterAdditionalShareClasses)
            );
            break;
          case appConstant.GM_FORTH_TAB:
            gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
              angular.copy(gm.masterAgendaDetails)
            );
            break;
          case appConstant.GM_SIXTH_TAB:
            gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
              angular.copy(gm.masterAdditionalDocuments)
            );
            //Below line is added to clear the error message
            $scope.documentFileSizeError = [];
            $scope.checkMaxFileSizeViolations();
            break;
          default:
        }
        gm.currentTabId = tabId;
      };
      $scope.prevNextSaveGm = function (tabId, prevNextFlag) {
        if (gm.createData.status !== 'D') {
          gm.prevTabId = tabId;
          gm.fromJumpTab = tabId;
          if (prevNextFlag === 'prev') {
            gm.currentTabId = parseInt(tabId, 10) - 1;
          } else {
            gm.currentTabId = parseInt(tabId, 10) + 1;
          }
          switch (gm.currentTabId.toString()) {
            case appConstant.GM_FIRST_TAB:
              gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
                angular.copy(gm.createData.gmDetailVOList)
              );
              $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
              break;
            case appConstant.GM_THIRD_TAB:
              gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
                angular.copy(gm.additionalShareClasses)
              );
              break;
            case appConstant.GM_FORTH_TAB:
              gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
                angular.copy(gm.DynamicAgendaItems)
              );
              break;
            case appConstant.GM_SIXTH_TAB:
              gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
                angular.copy(gm.DynamicAdditionalDocuments)
              );
              break;
            default:
          }
          return;
        }
        $scope.createGMLoading = true;
        GeneralMeetingDataLanguageService.setLanguage($scope.formLanguage);
        switch (tabId.toString()) {
          case appConstant.GM_FIRST_TAB:
            gm.createGMTypeLocationData('section');
            break;
          case appConstant.GM_SECOND_TAB:
            gm.createScheduleData('section');
            break;
          case appConstant.GM_THIRD_TAB:
            gm.createShareClassData('section');
            break;
          case appConstant.GM_FORTH_TAB:
            gm.createAgendaData('section');
            break;
          case appConstant.GM_FIFTH_TAB:
            gm.createGmFeaturesData('section');
            break;
          case appConstant.GM_SIXTH_TAB:
            gm.createGMDocumntsData('section');
            break;
          default:
        }
        gm.prevTabId = tabId;
        gm.fromJumpTab = tabId;
        if (prevNextFlag === 'prev') {
          gm.currentTabId = parseInt(tabId, 10) - 1;
        } else {
          gm.currentTabId = parseInt(tabId, 10) + 1;
        }
        switch (gm.currentTabId.toString()) {
          case appConstant.GM_FIRST_TAB:
            gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
              angular.copy(gm.masterTypeAndLocationDetails)
            );
            $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
            break;
          case appConstant.GM_THIRD_TAB:
            gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
              angular.copy(gm.masterAdditionalShareClasses)
            );
            break;
          case appConstant.GM_FORTH_TAB:
            gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
              angular.copy(gm.masterAgendaDetails)
            );
            break;
          case appConstant.GM_SIXTH_TAB:
            gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
              angular.copy(gm.masterAdditionalDocuments)
            );
            break;
          default:
        }
      };
      $scope.statusCheckPreSave = function (sendApproveStatus) {
        $timeout(function () {
          if (gm.createData.status !== 'D') {
            $scope.dateErrorVO = validateDate(
              $scope.createData.gmScheduleVO,
              $scope.dateErrorVO
            );
            if ($scope.dateErrorVO.validDates === false) {
              $scope.invalidFormError = $filter('translate')(
                'label_allSectionNotFilled'
              );
            } else {
              $scope.disableSaveForValidation = true;
              $scope.createGMLoading = true;
              gm.sendToIssuerFieldsRequired = true;
              $scope.callSendToIssuer = false;
              $timeout(function () {
                if ($scope.myForm.$valid) {
                  switch (gm.currentTabId.toString()) {
                    case appConstant.GM_FIRST_TAB:
                      $scope.validateTl = true;
                      gm.createGMTypeLocationData('save');
                      break;
                    case appConstant.GM_SECOND_TAB:
                      $scope.validateSc = true;
                      gm.createScheduleData('save');
                      break;
                    case appConstant.GM_THIRD_TAB:
                      $scope.validateSh = true;
                      gm.createShareClassData('save');
                      break;
                    case appConstant.GM_FORTH_TAB:
                      $scope.validateAg = true;
                      gm.createAgendaData('save');
                      break;
                    case appConstant.GM_FIFTH_TAB:
                      $scope.confirmationBoxPostValidation();
                      break;
                    case appConstant.GM_SIXTH_TAB:
                      $scope.validateDoc = true;
                      gm.createGMDocumntsData('save');
                      break;
                    default:
                  }
                } else {
                  $scope.disableSaveForValidation = false;
                  $scope.createGMLoading = false;
                  $scope.invalidFormError = $filter('translate')(
                    'label_allSectionNotFilled'
                  );
                }
              }, 1100);
            }
          } else {
            $scope.saveBtnGMSection(sendApproveStatus);
          }
        }, 500);
      };
      $scope.remainOnCurrentPage = function () {
        $scope.createGMLoading = false;
        $timeout(function () {
          $('a#' + gm.fromJumpTab).trigger('click');
        }, 100);
      };
      $scope.confirmationBoxPostValidation = function () {
        $scope.invalidFormError = '';
        $scope.modalHeaderNonDraft =
          'Modify ' +
          $filter('angentStatus')($scope.statusCalculated) +
          ' meeting';
        $scope.modalBodyNonDraft =
          'You are about to modify a ' +
          $filter('angentStatus')($scope.statusCalculated) +
          ' meeting?';
        $scope.modalLeftButtonNonDraft = 'Save';
        $scope.modalRightButtonNonDraft = $filter('translate')('label_cancel');
        $('#pop5').modal('show');
        $scope.disableSaveForValidation = false;
        $scope.createGMLoading = false;
        $scope.validateTl = false;
        $scope.validateSc = false;
        $scope.validateSh = false;
        $scope.validateAg = false;
        $scope.validateDoc = false;
      };
      $scope.saveBtnGMSection = function (sendApproveStatus) {
        $scope.disableSaveForValidation = true;
        if (!(sendApproveStatus === null || sendApproveStatus === undefined)) {
          $scope.callSendToIssuer = true;
        }
        $scope.sendApproveStatus = sendApproveStatus;
        gm.sendToIssuerFieldsRequired = false;
        GeneralMeetingDataLanguageService.setLanguage($scope.formLanguage);
        switch (gm.currentTabId.toString()) {
          case appConstant.GM_FIRST_TAB:
            gm.createGMTypeLocationData('save');
            break;
          case appConstant.GM_SECOND_TAB:
            gm.createScheduleData('save');
            break;
          case appConstant.GM_THIRD_TAB:
            gm.createShareClassData('save');
            break;
          case appConstant.GM_FORTH_TAB:
            gm.createAgendaData('save');
            break;
          case appConstant.GM_FIFTH_TAB:
            gm.createGmFeaturesData('save');
            break;
          case appConstant.GM_SIXTH_TAB:
            gm.createGMDocumntsData('save');
            break;
          default:
        }
      };
      $scope.disabled = function () {
        if ($scope.addInviteesDisabled) {
          return true;
        }
      };
      $scope.previousTab = function () {
        $timeout(function () {
          $('.nav-tabs > .active').prev('li').find('a').trigger('click');
        }, 100);
      };
      $scope.nextTab = function () {
        timeout(function () {
          $('.nav-tabs > .active').next('li').find('a').trigger('click');
        }, 100);
      };
      function replaceValidationUI(form) {
        form.addEventListener(
          'invalid',
          function (event) {
            event.preventDefault();
          },
          true
        );
        form.addEventListener('submit', function (event) {
          if (!this.checkValidity()) {
            event.preventDefault();
          }
        });
      }
      $scope.switchFormLanguage = function (lang) {
        //SFCBP-1578 - Fix
        $scope.countryList = GeneralMeetingDataLanguageService.getLangSpecificCountries(
          $scope.countryListMaster,
          lang
        );
        if (gm.createData.status !== 'D') {
          GeneralMeetingDataLanguageService.setLanguage(lang);
          $scope.formLanguage = lang;
          switch (gm.currentTabId.toString()) {
            case appConstant.GM_FIRST_TAB:
              gm.createData.gmDetailVOList = GeneralMeetingDataLanguageService.getGetTypeAndLocationDetailsCreateGM(
                angular.copy(gm.createData.gmDetailVOList)
              );
              $scope.bindTypeLocationData(gm.createData.gmDetailVOList);
              break;
            case appConstant.GM_THIRD_TAB:
              gm.additionalShareClasses = GeneralMeetingDataLanguageService.getShareClassDetailsCreateGM(
                angular.copy(gm.additionalShareClasses)
              );
              break;
            case appConstant.GM_FORTH_TAB:
              gm.DynamicAgendaItems = GeneralMeetingDataLanguageService.getAgendaDetailsCreateGM(
                gm.DynamicAgendaItems
              );
              break;
            case appConstant.GM_SIXTH_TAB:
              gm.DynamicAdditionalDocuments = GeneralMeetingDataLanguageService.getDocumentDetailsCreateGM(
                gm.DynamicAdditionalDocuments
              );
              break;
          }
          return;
        }
        if (lang === $scope.formLanguage) {
          return;
        }
        $scope.createGMLoading = true;
        GeneralMeetingDataLanguageService.setLanguage(lang);
        switch (gm.currentTabId.toString()) {
          case appConstant.GM_FIRST_TAB:
            $scope.submitted = true;
            $scope.createGMLoading = false;
            if ($scope.typeAndLocationForm.$valid) {
              gm.createGMTypeLocationData('languageChange', lang);
            }
            break;
          case appConstant.GM_SECOND_TAB:
            break;
          case appConstant.GM_THIRD_TAB:
            gm.createShareClassData('languageChange', lang);
            break;
          case appConstant.GM_FORTH_TAB:
            gm.createAgendaData('languageChange', lang);
            break;
          case appConstant.GM_FIFTH_TAB:
            break;
          case appConstant.GM_SIXTH_TAB:
            gm.createGMDocumntsData('languageChange', lang);
            break;
        }
      };
      $scope.prePopulateDate = function (generalMeetingDate) {
        $scope.dateErrorVO.validDates = false;
        if (
          generalMeetingDate === null ||
          generalMeetingDate === undefined ||
          generalMeetingDate === ''
        ) {
          return;
        } else if (generalMeetingDate < new Date()) {
          $scope.dateErrorVO.dateCheked9 = true;
        } else {
          generalMeetingDate.setHours(12, 0, 0, 0);
          $scope.dateErrorVO = createDateErrorVO();
          $scope.createData.gmScheduleVO = prePopulateDate(
            $scope.createData.gmScheduleVO,
            generalMeetingDate
          );
        }
      };

      $scope.updateVirtualParticipationAllowed = function () {
        $scope.isVirtualMeetingNotEnabled =
            !$scope.virtualParticipationSelectedinShareClass;
        if ($scope.virtualParticipationSelectedinShareClass
            || gm.virtualMeetingAllowed === true) {
          // if the virtualMeetingAllowed flag is set for the first time,
          // pre-fill the updateVirtualParticipationDateTime with a suitable
          // default value
          if (!gm.virtualMeetingDate) {
            if (gm.generalMeetingDate) {
              gm.virtualMeetingDate = new Date(gm.generalMeetingDate);
            } else {
              // in the odd case that the virtualMeetingAllowed is
              // enabled without a valid general meeting date,
              // use now a virtualMeetingDate
              gm.virtualMeetingDate = new Date();
            }
          }
        }
      };

      $scope.updateVirtualParticipationDateTime = function (
        generalMeetingDate
      ) {
        if (gm.virtualMeetingAllowed === false) {
          // update the virtual meeting date along with the meeting date
          // if virtual meetings are disabled. This will provide the agent
          // with a good starting value when virtual meetings are enabled
          //
          // If the virtual meetings are already enabled, we assume that
          // the agent already has a suitable value, so we don't touch it
          // anymore
          gm.virtualMeetingDate = new Date(generalMeetingDate);
        }
      };

      $scope.updateTimes = function (generalMeetingDate) {
        // Update the time part of the dates in the schedule that are linked to
        // the generalMeetingDate
        var hours = generalMeetingDate.getHours();
        var mins = generalMeetingDate.getMinutes();
        $scope.createData.gmScheduleVO.recordDate = new Date(
          $scope.createData.gmScheduleVO.recordDate
        ).setHours(hours, mins, 0, 0);
      };

      //Below function calls from HTML on change of dates to validate
      $scope.validateDate = function (generalMeetingDate, selectedDate) {
        if (generalMeetingDate && selectedDate) {
          $scope.dateErrorVO = createDateErrorVO();
          $scope.dateErrorVO = validateDate(
            $scope.createData.gmScheduleVO,
            $scope.dateErrorVO
          );
          var regReleaseDate = new Date(
            $scope.createData.gmScheduleVO.registrationReleaseDate
          ).setHours(0, 0, 0, 0);
          var intDeadlineDate = new Date(
            $scope.createData.gmScheduleVO.intermediaryDeadlineDate
          ).setHours(0, 0, 0, 0);
          if (
            ($scope.dateErrorVO.dateCheked1 === 5 ||
              !$scope.dateErrorVO.dateCheked1) &&
            regReleaseDate === intDeadlineDate
          ) {
            if ($scope.inDtime >= $scope.shDtime) {
              $scope.dateErrorVO.dateCheked1 = 6;
            } else {
              $scope.dateErrorVO.dateCheked1 = false;
            }
          }
          $scope.dateError = $scope.dateErrorVO.validDates;
        }
      };

      $scope.bindTypeLocationData = function (gmDetailInfoAll) {
        var gmDetailInfo = $filter('filter')(gmDetailInfoAll, {
          languageCode: $scope.formLanguage,
        })[0];
        $scope.locationName = $filter('htmlDecode')(gmDetailInfo.locationName);
        $scope.locationTicket = $filter('htmlDecode')(
          gmDetailInfo.locationTicket
        );
        $scope.street = $filter('htmlDecode')(gmDetailInfo.street);
        $scope.city = $filter('htmlDecode')(gmDetailInfo.city);
        if (
          gmDetailInfo.country === '' ||
          gmDetailInfo.country === null ||
          gmDetailInfo.country === undefined
        ) {
          $scope.country = 'NLD';
        } else {
          $scope.country = gmDetailInfo.country;
        }
        $scope.selectedCountry = $filter('filter')($scope.countryList, {
          isoCountryCode: $scope.country,
        });
        $scope.meetinName = $filter('htmlDecode')(gmDetailInfo.meetinName);
        $scope.submitted = false;
        // $scope.typeAndLocationForm.$setPristine();
      };
      $(document).ready(function () {
        var forms = document.querySelectorAll('form');
        for (var i = 0; i < forms.length; i++) {
          replaceValidationUI(forms[i]);
        }
      });

      // Validate that the enable join by date should be only after shareholder access date
      $scope.validateVirtualMeetingDate = function (virtualDate) {
        $scope.isVirtualMeetingEnableDateValid = false;
        if (
          virtualDate &&
          virtualDate < $scope.createData.gmScheduleVO.shareholderAccessDate
        ) {
          $scope.isVirtualMeetingEnableDateValid = true;
          $scope.virtualMeetingEnableDateValidErrorMsg = $filter('translate')(
            'label_Enable_join_date_error_msg'
          );
        }
      };

      gm.copyAgendaItemsToOtherLangs = function () {
        let selectedLang = $filter('uppercase')($scope.formLanguage);
        angular.forEach(gm.DynamicAgendaItems, function (agenda) {
          let source = $filter('filter')(agenda.agendaDetailVOList, {
            languageCode: selectedLang,
          });

          if (source && source.length > 0) {
            if ($scope.selectedDutch && selectedLang !== 'NL') {
              let copyNL = angular.copy(source[0]);
              copyNL.languageCode = 'NL';
              source.push(copyNL);
            }
            if ($scope.selectedGerman && selectedLang !== 'DE') {
              let copyDE = angular.copy(source[0]);
              copyDE.languageCode = 'DE';
              source.push(copyDE);
            }
            if ($scope.selectedEnglish && selectedLang !== 'EN') {
              let copyEN = angular.copy(source[0]);
              copyEN.languageCode = 'EN';
              source.push(copyEN);
            }
            if ($scope.selectedFrench && selectedLang !== 'FR') {
              let copyFR = angular.copy(source[0]);
              copyFR.languageCode = 'FR';
              source.push(copyFR);
            }
            if ($scope.selectedSpanish && selectedLang !== 'ES') {
              let copyES = angular.copy(source[0]);
              copyES.languageCode = 'ES';
              source.push(copyES);
            }
          }
          agenda.agendaDetailVOList = source;
          $scope.agendaItemsCopySuccess = $filter('translate')(
            'label_agendaItemsCopied'
          );
        });
      };

      // This function copies the street and city of the currently
      // selected language in the type and location section
      // to the other languages
      // It should be called when saving the type and location data
      // and upon switching to another language
      $scope.copyAddressDetailsToOtherLangs = function (clickedLang) {
        let selectedLang = $filter('uppercase')($scope.formLanguage);

        // Get an array of selected (checked) languages
        let selectedLangs = gm.getSelectedLangs();

        // The array object containing the address details
        // for the currently selected language
        // is the source for the other languages
        let source = $filter('filter')(gm.createData.gmDetailVOList, {
          languageCode: selectedLang,
        });

        if (source && source.length > 0) {
          angular.forEach(selectedLangs, function (lang) {
            let details = $filter('filter')(gm.masterTypeAndLocationDetails, {
              languageCode: lang,
            });

            // If the current selected language is clicked again
            // then no data needs to be copied
            if (selectedLang !== lang && selectedLang !== clickedLang) {
              // If there are no details for the current language under iteration,
              // the source object will be fully copied, with only
              // the languageCode, street and city initialized
              if (details.length === 0) {
                let copy = angular.copy(source[0]);
                copy.country = '';
                copy.languageCode = lang;
                copy.locationName = '';
                copy.locationTicket = '';
                copy.meetinName = '';
                copy.novInstruction = 0;
                //The details are added to the source array
                source.push(copy);
              } else {
                // Else the city and street of the existing object will
                // be overwritten with the street and city of the source object
                details[0].city = $scope.city;
                details[0].street = $scope.street;
                //The details are added to the source array
                source.push(details[0]);
              }
            }
          });
          // All arrays in this controller containing the type and
          // location data are overwritten with the new source
          gm.createData.gmDetailVOList = source;
          gm.masterTypeAndLocationDetails = source;
        }
      };

      //This functions returns an array of selected (checked) languages
      gm.getSelectedLangs = function () {
        let selectedLangs = [];

        if ($scope.selectedEnglish) {
          selectedLangs.push('EN');
        }
        if ($scope.selectedDutch) {
          selectedLangs.push('NL');
        }
        if ($scope.selectedFrench) {
          selectedLangs.push('FR');
        }
        if ($scope.selectedGerman) {
          selectedLangs.push('DE');
        }
        if ($scope.selectedSpanish) {
          selectedLangs.push('ES');
        }

        return selectedLangs;
      };

      $scope.languageCopyEnabled = function () {
        return (
          $scope.selectedFrench * 1 +
            $scope.selectedEnglish * 1 +
            $scope.selectedDutch * 1 +
            $scope.selectedGerman * 1 +
            $scope.selectedSpanish * 1 >
          1
        );
      };

      $scope.getIssuerLogo($stateParams.issuerId);

      /**
       * CSD's and IssuerPreferences:
       */
      csdService
        .list()
        .then((csds) => {
          $scope.csds = csds;
        });

      // Avoid unnecessary API calls with issuerId check
      if($stateParams.issuerId !== undefined) {
        issuerPreferencesService
        .getCsdPreferenceForIssuer($stateParams.issuerId)
        .then((pref) => {
          $scope.csdPreference = pref;
        });
      }


      gm.prefillShareclassesWithCsdPreference = () => {
        // When a shareClass doesn't have a CSD set, prefill it with the csdPreference of the issuer
        gm.masterAdditionalShareClasses.forEach((additionalSc) => {
          if (!additionalSc.csdId) {
            additionalSc.csdId = $scope.csdPreference || null;
          }
        });
        gm.additionalShareClasses.forEach((additionalSc) => {
          if (!additionalSc.csdId) {
            additionalSc.csdId = $scope.csdPreference || null;
          }
        });
      };

      /**
       * Returns the csdId which has the highest order of preference.
       *
       * Returns the csdPreference if it exists. If not:
       * Returns the id the first CSD with 'nl' in the name (hoping it's Euroclear NL), otherwise
       * Returns the id of the first csd if it exists. If it doesn't, throw.
       */
      function getPreferentialCsdId() {
        if ($scope.csdPreference) {
          return $scope.csdPreference;
        }

        const euroclearNl = $scope.csds.find((csd) =>
          csd.name.toLowerCase().includes('nl')
        );
        if (euroclearNl) {
          return euroclearNl.csdId;
        } else if ($scope.csds && $scope.csds.length) {
          return $scope.csds[0].csdId;
        } else {
          throw new Error("Couldn't find a eligible csdId from anywhere!");
        }
      }
    },
  ]);
