/**
 * @ngdoc sharePlanHolderController
 * @description This controller is used for general meeting module.
 * @requires generalMeetingHomeModule where there is a service call to
 *           sharePlanHolderGMList
 * @requires $scope and $log
 */

angular
  .module('generalMeetingHomeModule')
  .controller('sharePlanHolderController', [
    '$scope',
    '$log',
    '$state',
    'shareHolderService',
    'generalMeetingService',
    '$filter',
    function (
      $scope,
      $log,
      $state,
      shareHolderService,
      generalMeetingService,
      $filter
    ) {
      $scope.onLoadSpinner = true;
      shareHolderService
        .getSHGeneralMeetings()
        .query()
        .$promise.then(
          function (data) {
            $scope.gmList = [];
            $scope.generalMeetinglist = data;
            generalMeetingService
              .getCurrentCETTime()
              .query()
              .$promise.then(
                function (data) {
                  var cetDate = $filter('filter')(
                    data,
                    {
                      timezone: 'CET',
                    },
                    true
                  );
                  var gmtDate = $filter('filter')(
                    data,
                    {
                      timezone: 'GMT',
                    },
                    true
                  );
                  $scope.generalMeetingSPlist = [];
                  var curDate = '';
                  angular.forEach($scope.generalMeetinglist, function (item) {
                    if (item.generalMeetingDateStr.indexOf('CET') > -1) {
                      curDate = cetDate[0].currentMillis;
                    } else {
                      curDate = gmtDate[0].currentMillis;
                    }
                    if (
                      item.shareholderAccessDateMiilis < curDate &&
                      item.shareholderDeadlineDateMiilis > curDate &&
                      item.status === 'L'
                    ) {
                      $scope.gmList.push(item);
                    }
                  });
                },
                function (error) {
                  $log.debug(error);
                }
              );
            $scope.onLoadSpinner = false;
          },
          function (error) {
            $scope.onLoadSpinner = false;
            return error;
          }
        );
      $scope.goGMDetails = function (
        meetingId,
        meetingName,
        issuerId,
        issuerName,
        generalMeetingDateStr,
        status,
        intermediaryDeadlineDate,
        intermediaryDeadlineDateStr
      ) {
        $state.go('generalMeetings', {
          selectedIndex: 0,
          meetingId: meetingId,
          issuerId: issuerId,
          issuerName: issuerName,
          status: status,
          intermediaryDateTimeStamp: intermediaryDeadlineDate,
          intermediaryDate: intermediaryDeadlineDateStr,
          breadCrumbDetails: [],
        });
      };
    },
  ]);
