/**
 * @ngdoc controller
 * @name generalMeetingHomeModule.AuditController
 * @description This controller is used for audit trail and templates. This mainly
 *              deals with viewing the list email audit trail, audit trail and templates. It also deals with editing templates.
 * @requires AuditTrailService is the service which is being used to interact with REST
 * @requires $scope for storing information in scope
 * @requires $log mainly used for logging. Used only $log.debug and could be enabled/disabled based on need.
 * @requires $sessionStorage for storing current data when next set of data is being fetched be service
 * @requires $state for state routing
 * @requires $filter to translate messages which are declared in locale files
 * @requires PaginationSvc for pagination
 * @requires BuildInfoRestService
 * @requires BuildInfoService
 */
'use strict';
angular.module('generalMeetingHomeModule').controller('AuditController', [
  '$scope',
  '$sessionStorage',
  'AuditTrailService',
  '$log',
  '$state',
  'PaginationSvc',
  '$filter',
  '$timeout',
  '$uibModal',
  'BuildInfoRestService',
  'BuildInfoService',
  function (
    $scope,
    $sessionStorage,
    AuditTrailService,
    $log,
    $state,
    PaginationSvc,
    $filter,
    $timeout,
    $uibModal,
    BuildInfoRestService,
    BuildInfoService
  ) {
    var maxCount = 0;
    var pageFrom = 1;
    var pageFromEmail = 1;
    $scope.selectedTrail = '';
    $scope.adminAuditLoading = true;
    $scope.emailAuditLoading = true;
    $scope.validationErrorFlag = false;
    $scope.validationErrorMsg = '';
    $sessionStorage.emailInitialData = null;
    $scope.editTemplate = false;
    $scope.emailDetailsTab = false;
    $scope.templateContent = [];
    $scope.trixContent = '';
    $scope.selectedType = '';
    $scope.title = '';
    $scope.templateSearchData = {};
    $sessionStorage.emailAudit = null;
    $scope.auditTrailSrchNoData = false;
    $scope.htmlFile = {};

    $scope.$watch('templateFile', function (file) {
      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, 'UTF-8');
        reader.onload = function (evt) {
          $scope.trixContent = evt.target.result;
          $scope.$apply();
        };
        reader.onerror = function (evt) {};
      }
    });

    // call the buildinfo rest service and construct the model data
    BuildInfoRestService.getBuildInfo().then(function (response) {
      $scope.buildinfo = BuildInfoService.createBuildInfo(response.data);
    });
    // Below is the function to set pagination
    $scope.setPage = function (page) {
      $scope.pager = [];
      //Below condition is to call the service to get the next 100 records
      if (
        page % 10 === 0 &&
        maxCount / 100 > 1 &&
        page <= $scope.noOfPages &&
        !$scope.searchResult
      ) {
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        if ($scope.selectedTrail === 'Audit') {
          pageFrom = pageFrom + 1;
          $scope.adminAuditLoading = true;
          AuditTrailService.getAllAuditTrailsData(pageFrom)
            .query()
            .$promise.then(
              function (data) {
                $scope.auditTrailsData = data;
                $scope.adminAuditLoading = false;
                if ($sessionStorage.emailInitialData !== null) {
                  maxCount = $sessionStorage.maxCount;
                  $sessionStorage.emailInitialData = $sessionStorage.emailInitialData.concat(
                    $scope.auditTrailsData
                  );
                } else {
                  $sessionStorage.emailInitialData = $scope.auditTrailsData;
                  $sessionStorage.maxCount = data[0].count;
                  maxCount = data[0].count;
                }
                $scope.dummyItems = $sessionStorage.emailInitialData;
                // get pager object from service
                $scope.pager = PaginationSvc.GetPager(
                  $scope.dummyItems.length,
                  page
                );
                $scope.noOfPages = $scope.pager.totalPages;
                $log.debug($scope.pager);

                // get current page of items
                $scope.items = $scope.dummyItems.slice(
                  $scope.pager.startIndex,
                  $scope.pager.endIndex + 1
                );
              },
              function (error) {
                $log.debug(error);
              }
            );
        } else if ($scope.selectedTrail === 'Email') {
          pageFromEmail = pageFromEmail + 1;
          $scope.emailAuditLoading = true;
          AuditTrailService.getAllEmailAuditTrailsData(pageFromEmail)
            .query()
            .$promise.then(
              function (data) {
                $scope.emailAuditTrailsData = data;
                $scope.emailAuditLoading = false;
                if ($sessionStorage.emailAudit !== null) {
                  maxCount = $sessionStorage.maxCount;
                  $sessionStorage.emailAudit = $sessionStorage.emailAudit.concat(
                    $scope.emailAuditTrailsData
                  );
                } else {
                  $sessionStorage.emailAudit = $scope.emailAuditTrailsData;
                  $sessionStorage.maxCount = data[0].count;
                  maxCount = data[0].count;
                }
                $scope.dummyItems = $sessionStorage.emailAudit;
                // get pager object from service
                $scope.pager = PaginationSvc.GetPager(
                  $scope.dummyItems.length,
                  page
                );
                $scope.noOfPages = $scope.pager.totalPages;
                $log.debug($scope.pager);

                // get current page of items
                $scope.items = $scope.dummyItems.slice(
                  $scope.pager.startIndex,
                  $scope.pager.endIndex + 1
                );
              },
              function (error) {
                $log.debug(error);
              }
            );
        }
      } else {
        if (page < 1 || page > $scope.pager.totalPages) {
          return;
        }
        // get pager object from service
        $scope.pager = PaginationSvc.GetPager($scope.dummyItems.length, page);
        $log.debug($scope.pager);
        $scope.noOfPages = $scope.pager.totalPages;
        // get current page of items
        $scope.items = $scope.dummyItems.slice(
          $scope.pager.startIndex,
          $scope.pager.endIndex + 1
        );
      }
    };
    // Below is the function call from HTML to reset the search criterias of audit trail list
    $scope.ClearSearchFields = function () {
      $scope.startDateModified = '';
      $scope.endDateModified = '';
      $scope.selectedAction = '';
      $scope.entityChanged = '';
    };
    $scope.aTResetDefaultTableData = function () {
      $scope.dummyItems = $scope.forSrchRestData;
      $scope.pager = {};
      $scope.setPage(1);
    };
    // Below is the function to get all the audit trails.
    $scope.getAuditTrailList = function () {
      $sessionStorage.emailAudit = null;
      $scope.selectedTrail = 'Audit';
      $scope.validationErrorFlag = false;
      $scope.validationErrorMsg = '';
      $scope.adminAuditLoading = true;
      AuditTrailService.getActionDropDown()
        .query()
        .$promise.then(
          function (data) {
            $scope.actions = data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      AuditTrailService.getAllAuditTrailsData(pageFrom)
        .query()
        .$promise.then(
          function (data) {
            $scope.searchResult = null;
            $scope.auditTrailsData = data;
            $scope.forSrchRestData = $scope.auditTrailsData;
            if ($sessionStorage.emailInitialData !== null) {
              maxCount = $sessionStorage.maxCount;
              $sessionStorage.emailInitialData = $sessionStorage.emailInitialData.concat(
                $scope.auditTrailsData
              );
            } else {
              $sessionStorage.emailInitialData = $scope.auditTrailsData;
              $sessionStorage.maxCount = data[0].count;
              maxCount = data[0].count;
            }

            $scope.dummyItems = $sessionStorage.emailInitialData;
            $scope.pager = {};
            $scope.setPage(1);
            $scope.adminAuditLoading = false;
          },
          function (error) {
            $log.debug(error);
          }
        );
      $scope.ClearSearchFields();
    };
    // Below is the function to get all the email audit trails
    $scope.getEmailAuditTrailList = function () {
      $scope.selectedTrail = 'Email';
      $sessionStorage.emailInitialData = null;
      $scope.emailAuditLoading = true;
      $scope.emailDetailsTab = false;
      document.getElementById('emaildetails').style.display = 'none';
      document.getElementById('emailaudittrail').style.display = 'block';
      document.getElementById('emailaudittrail1').style.display = 'block';
      document.getElementById('emailaudittrail2').style.display = 'block';
      document.getElementById('pagenavigation').style.display = 'block';
      AuditTrailService.getAllEmailAuditTrailsData(pageFromEmail)
        .query()
        .$promise.then(
          function (data) {
            $scope.searchResult = null;
            $scope.emailAuditTrailsData = data;
            $scope.emailAuditLoading = false;
            if ($sessionStorage.emailAudit !== null) {
              maxCount = $sessionStorage.maxCount;
              $sessionStorage.emailAudit = $sessionStorage.emailAudit.concat(
                $scope.emailAuditTrailsData
              );
            } else {
              $sessionStorage.emailAudit = $scope.emailAuditTrailsData;
              $sessionStorage.maxCount = data[0].count;
              maxCount = data[0].count;
            }

            $scope.dummyItems = $sessionStorage.emailAudit;
            $scope.pager = {};
            $scope.setPage(1);
            $scope.emailAuditLoading = false;
          },
          function (error) {
            $log.debug(error);
          }
        );
    };
    // Below is the function to go back to email audit trail after viewing an email audit details
    $scope.backToEmailAuditTrail = function () {
      document.getElementById('emaildetails').style.display = 'none';
      document.getElementById('emailaudittrail').style.display = 'block';
      document.getElementById('emailaudittrail1').style.display = 'block';
      document.getElementById('emailaudittrail2').style.display = 'block';
      document.getElementById('pagenavigation').style.display = 'block';
    };
    //Method to validate all the date inputs
    $scope.dateValidation = function () {
      $scope.validationErrorFlag = false;
      $scope.validationErrorMsg = '';
      if (
        ($scope.startDateModified !== null &&
          $scope.endDateModified !== null) ||
        ($scope.startDateModified !== undefined &&
          $scope.endDateModified !== undefined)
      ) {
        if (
          new Date($scope.startDateModified) > new Date($scope.endDateModified)
        ) {
          $scope.validationErrorFlag = true;
          $scope.validationErrorMsg = $filter('translate')(
            'selectToDate_error'
          );
        } else {
          $scope.validationErrorFlag = false;
          $scope.validationErrorMsg = '';
        }
      }
    };
    // Below is the function call to search for particular audit trail
    $scope.searchAuditTrail = function (
      startDate,
      endDate,
      actionCode,
      entityChanged
    ) {
      $scope.searchAuditLoading = true;
      if (endDate) {
        var timeData = new Date(endDate);
        timeData.setMinutes(59);
        timeData.setHours(23);
        endDate = timeData.getTime();
      }
      if (startDate) {
        startDate = startDate.getTime();
      }
      $scope.auditSearchData = {
        startDateModified: startDate,
        endDateModified: endDate,
        actionCode: actionCode,
        afterValue: entityChanged,
      };
      AuditTrailService.searchAuditTrail(
        $scope.auditSearchData,
        'secureToken'
      ).then(
        function (response) {
          $scope.searchResult = 1;
          $scope.auditTrailsData = response.data;
          $scope.dummyItems = $scope.auditTrailsData;
          $scope.pager = {};
          $scope.setPage(1);
          $scope.searchAuditLoading = false;
        },
        function (error) {
          $log.debug(error);
          $scope.searchAuditLoading = false;
        }
      );
    };
    // Below is the function call to search for particular audit trail
    $scope.searchEmailAudits = function (searchReceiver) {
      $scope.searchEmailAuditLoading = true;
      if (searchReceiver) {
        $scope.emailAuditSearchData = {
          emailAddress: searchReceiver,
          pageNo: '1',
          size: '500',
          userName: searchReceiver,
          prodCode: 'GM',
        };
        AuditTrailService.searchEmailAudit(
          $scope.emailAuditSearchData,
          'secureToken'
        ).then(
          function (response) {
            $scope.searchResult = 1;
            $scope.auditTrailsData = response.data;
            $scope.dummyItems = $scope.auditTrailsData;
            $scope.pager = {};
            $scope.setPage(1);
            $scope.searchEmailAuditLoading = false;
          },
          function (error) {
            $log.debug(error);
            $scope.searchEmailAuditLoading = false;
          }
        );
      } else {
        $scope.getEmailAuditTrailList();
        //Added the below line to hide the spinner after the service call made for no value entered in search box,
        //Spinner was keeps on loading when the below code was not there
        $scope.searchEmailAuditLoading = false;
      }
    };
    //Below is the function call on click of email id in email audit trail to view details
    $scope.showEmailDetails = function (
      emailBody,
      emailSubject,
      receiverAddress,
      sentDate,
      attachmentVO
    ) {
      $scope.emailhtml = emailBody;
      $scope.emailSubject = emailSubject;
      $scope.receiverAddress = receiverAddress;
      $scope.sentDate = sentDate;
      $scope.attachmentVO = attachmentVO;
      $scope.emailDetailsTab = true;
      document.getElementById('emaildetails').style.display = 'block';
      document.getElementById('emailaudittrail').style.display = 'none';
      document.getElementById('emailaudittrail1').style.display = 'none';
      document.getElementById('emailaudittrail2').style.display = 'none';
      document.getElementById('pagenavigation').style.display = 'none';
    };
    //Below is the function to navigate to templates tab and load options for templates type dropdown.
    $scope.getTemplates = function () {
      $scope.subjectSelected = false;
      $scope.editTemplate = false;
      $sessionStorage.emailInitialData = null;
      $sessionStorage.emailAudit = null;
      AuditTrailService.getTemplateTypes().then(
        function (response) {
          $scope.templatetypes = $filter('filter')(response.data, {
            templateCode: '!UPLOAD',
          });
          $scope.emailTemplatesOptions = [];
          $scope.templateContent = [];
          $scope.dummyItems = [];
          $scope.pager = {};
          $scope.setPage(1);
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    //Below is the function call on change of template type option to load relevant options in subjects dropdown
    $scope.getSubjectOptions = function (selectedTemplateType) {
      if (selectedTemplateType === 'CERT') {
        $scope.selectedType = 'CERT';
        AuditTrailService.getSubjectOptions().then(
          function (response) {
            $scope.emailTemplatesOptions = response.data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      } else if (selectedTemplateType === 'EMAIL') {
        $scope.selectedType = 'EMAIL';
        AuditTrailService.getEmailSubjectOptions().then(
          function (response) {
            $scope.emailTemplatesOptions = response.data.sort((a, b) => {
              if (a.type === 'ALL') {
                return -1; // 'ALL' comes before everything else
              } else if (b.type === 'ALL') {
                return 1; // Everything else comes after 'ALL'
              } else {
                return a.type.localeCompare(b.type); // Sort alphabetically
              }
            });
          },
          function (error) {
            $log.debug(error);
          }
        );
      } else if (selectedTemplateType === 'WEB') {
        $scope.selectedType = 'WEB';
        AuditTrailService.getWebSubjectOptions().then(
          function (response) {
            $scope.emailTemplatesOptions = response.data;
          },
          function (error) {
            $log.debug(error);
          }
        );
      }
    };
    //Below is function call from HTML for modal window.
    $scope.changeDeleteId = function (id, code) {
      $scope.deleteTempID = id;
      $scope.deleteTempCode = code;
      $scope.modalHeader = $filter('translate')('label_modalDelete');
      $scope.modalBody = $filter('translate')('label_modalConfirmDelTemplate');
      $scope.modalFooter = '';
      $scope.modalLeftButton = $filter('translate')('label_yes');
      $scope.modalRightButton = $filter('translate')('label_no');
    };
    //Below is the function call from HTML to search templates
    $scope.searchTemplate = function (
      templateCode,
      type,
      searchType,
      selectedSubject,
      selectedScreen,
      title
    ) {
      $scope.selectedScreen = selectedScreen;
      $scope.searchType = searchType;
      $scope.title = title;
      $scope.templateContent = [];
      if ($scope.selectedType === 'WEB') {
        $scope.templateSearchData = {
          templateCode: templateCode,
          dropDownOne: type,
          dropDownTwoId: searchType,
          screenName: selectedScreen,
          dropDownOneKey: selectedSubject,
          productCode: 'GM',
        };
      } else {
        if (selectedSubject === 'ALL' && $scope.selectedType === 'EMAIL') {
          $scope.templateSearchData = {
            templateCode: templateCode,
            dropDownOne: type,
            dropDownTwoId: searchType,
            dropDownOneKey: selectedSubject,
            productCode: 'GM',
          };
        } else {
          $scope.templateSearchData = {
            templateCode: templateCode,
            dropDownOne: type,
            dropDownTwoId: searchType,
            dropDownOneKey: selectedSubject,
          };
        }
      }

      AuditTrailService.searchTemplate(
        $scope.templateSearchData,
        'secureToken'
      ).then(
        function (response) {
          $scope.templatesData = response.data;
          if ($scope.selectedType === 'WEB') {
            $scope.templateContent = $scope.templatesData;
          } else {
            if (selectedScreen) {
              $scope.selectedIssuer = $filter('filter')(
                $scope.selectedSubject.typeSubSetVOList,
                { searchType: selectedScreen }
              );
            } else {
              $scope.selectedIssuer = $filter('filter')(
                $scope.selectedSubject.typeSubSetVOList,
                { searchType: 'Default' }
              );
            }
            angular.forEach($scope.templatesData, function (value, key) {
              $scope.templateContent = $scope.templateContent.concat([
                {
                  languageCode: response.data[key].languageCode,
                  templateId: response.data[key].templateId,
                  templateCode: response.data[key].templateCode,
                  sender: response.data[key].sender,
                  description1: response.data[key].description1,
                  issuerVO: response.data[key].issuerVO,
                  description2: response.data[key].description2,
                  tempDesc: response.data[key].description2,
                  templateTitle: response.data[key].templateTitle,
                },
              ]);
            });
          }

          $scope.dummyItems = $scope.templatesData;
          $scope.pager = {};
          $scope.setPage(1);
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    //Below is the function call from HTML to navigate to edit template section and prepopulate the data.
    $scope.editEmail = function (
      option,
      issuerName,
      subject,
      sender,
      description,
      templateCode,
      templateId,
      issuerId,
      templateTitle,
      editLanguageCode
    ) {
      $scope.editLanguageCode = editLanguageCode;
      $scope.templateTitle = templateTitle;
      $scope.successMessage = '';
      $scope.modalHeader = $filter('translate')('label_modalUpdateTemp');
      $scope.modalBody = $filter('translate')(
        'label_modalConfirmUpdateTemplate'
      );
      $scope.modalFooter = '';
      $scope.modalLeftButton = $filter('translate')('label_yes');
      $scope.modalRightButton = $filter('translate')('label_no');
      if (option === 'cancel') {
        $scope.editTemplate = false;
        $scope.trixContent = '';
        $('#templateUpload')[0].value = '';
      } else if (issuerName) {
        $scope.editTemplate = true;
        $scope.templateIssuer = issuerName;
        $scope.templateSubject = subject;
        $scope.templateSender = sender;
        $scope.trixContent = description;
        $log.debug($scope.trixContent);
        $scope.templateCode = templateCode;
        $scope.templateId = templateId;
        $scope.templateIssuerId = issuerId;
      } else {
        AuditTrailService.getIssuerList().then(
          function (response) {
            $scope.issuerList = response.data;
            $scope.editTemplate = true;
          },
          function (error) {
            $log.debug(error);
          }
        );
        $scope.templateIssuer = null;
        $scope.templateIssuerId = null;
        $scope.templateSubject = subject;
        $scope.templateSender = sender;
        $scope.trixContent = description;
        $log.debug($scope.trixContent);
        $scope.templateCode = templateCode;
        $scope.templateId = templateId;
      }
    };

    //Below is the function call from HTML to download the attachment in email details
    $scope.downloadEmailAttachment = function (attachmentId) {
      $scope.attachmentId = attachmentId;
      var pathList = window.location.pathname.split('/');
      if (pathList[1] === 'corporatebrokingportal') {
        $scope.downloaAttachment =
          window.location.origin +
          '/' +
          pathList[1] +
          'v1/emailAuditTrail/downloadAttachment/' +
          $scope.attachmentId +
          '?authid=' +
          $sessionStorage.userId;
      } else {
        $scope.downloaAttachment =
          window.location.origin +
          'v1/emailAuditTrail/downloadAttachment/' +
          $scope.attachmentId +
          '?authid=' +
          $sessionStorage.userId;
      }
    };
    //Below is the function call to update the changes for a template.
    $scope.updateTemplate = function (
      subject,
      description,
      sender,
      selectedIssuerId,
      selectedSubject
    ) {
      if ($scope.selectedType !== 'EMAIL') {
        sender = ' '; // TODO this is a quickfix in order to pass the BE validation, the value is not used in the BE, proper fix is registered in SFCBP-5843 to separate endpoints for different kind of templates
      }
      if ($scope.templateIssuerId) {
        $scope.issuerId = $scope.templateIssuerId;
      } else if (selectedIssuerId) {
        $scope.issuerId = selectedIssuerId;
      }

      if ($scope.selectedType === 'WEB') {
        $scope.requestDescription = description;
        $scope.updateTemplateData = {
          sender: sender,
          templateTitle: $scope.templateTitle,
          userId: $sessionStorage.userId,
          description1: selectedSubject,
          description2: $scope.requestDescription,
          templateCode: $scope.templateCode,
          templateId: $scope.templateId,
          languageCode: $scope.editLanguageCode,
        };
      } else {
        var descriptionString = description.toString();
        if (descriptionString.indexOf('<body') > -1) {
          $scope.requestDescription = descriptionString;
        } else {
          $scope.requestDescription =
            '<body text="#000000" link="#000000" alink="#000000" vlink="#000000">' +
            description +
            '</body>';
        }
        $scope.updateTemplateData = {
          templateTitle: $scope.templateTitle,
          userId: $sessionStorage.userId,
          description1: subject,
          description2: $scope.requestDescription,
          sender: sender,
          templateCode: $scope.selectedType,
          templateId: $scope.templateId,
          languageCode: $scope.editLanguageCode,
        };
        if ($scope.issuerId) {
          $scope.updateTemplateData.issuerId = $scope.issuerId;
        }
      }
      $log.debug(description);
      AuditTrailService.updateTemplate(
        $scope.updateTemplateData,
        'secureToken'
      ).then(
        function (response) {
          $scope.templateContent = [];
          AuditTrailService.searchTemplate(
            $scope.templateSearchData,
            'secureToken'
          ).then(
            function (response) {
              $scope.templatesData = response.data;
              if ($scope.selectedType === 'WEB') {
                $scope.templateContent = $scope.templatesData;
              } else {
                angular.forEach($scope.templatesData, function (value, key) {
                  $scope.templateContent = $scope.templateContent.concat([
                    {
                      languageCode: response.data[key].languageCode,
                      templateId: response.data[key].templateId,
                      templateCode: response.data[key].templateCode,
                      sender: response.data[key].sender,
                      description1: response.data[key].description1,
                      issuerVO: response.data[key].issuerVO,
                      description2: response.data[key].description2,
                      tempDesc: response.data[key].description2,
                      templateTitle: response.data[key].templateTitle,
                    },
                  ]);
                });
              }

              $scope.dummyItems = $scope.templatesData;
              $scope.pager = {};
              $scope.setPage(1);
              $scope.timedout = $uibModal.open({
                templateUrl: 'success-dialog.html',
              });
              $timeout(function () {
                $scope.timedout.close();
                $scope.timedout = null;
                $scope.successMessage = '';
                $scope.editTemplate = false;
              }, 2000);
            },
            function (error) {
              $log.debug(error);
            }
          );
        },
        function (error) {
          $log.debug(error);
          $scope.successMessage = error.data.errors[0].errorMessage;
        }
      );
    };
    //Below is the function to call to delete the template from template list.
    $scope.deleteTemplate = function (type, id) {
      $scope.templateType = type;
      $scope.templateId = id;
      AuditTrailService.deleteTemplate(
        $scope.templateType,
        $scope.templateId,
        'secureToken'
      ).then(
        function (response) {
          $scope.deleteSuccessMsg = response.data.message;
          $scope.searchTemplate(
            $scope.templateType,
            $scope.templateId,
            $scope.searchType
          );
        },
        function (error) {
          $log.debug(error);
        }
      );
    };
    //Below is the function call to search email audit trail when Enter key is pressed
    $scope.onEnterKey = function ($event) {
      if ($event.keyCode === 13) {
        if ($scope.searchReceiver) {
          $scope.searchEmailAudits($scope.searchReceiver);
        } else {
          $scope.getEmailAuditTrailList();
        }
      }
    };
    // call the function for first time
    $scope.getEmailAuditTrailList();

    $scope.goBackFn = function () {
      $state.go(
        $state.current,
        {
          selectedIndex: 1,
        },
        {
          reload: true,
          location: false,
        }
      );
    };

    /* date picker */

    $scope.inlineOptions = {
      customClass: getDayClass,
    };

    $scope.open1 = function () {
      $scope.popup1.opened = true;
    };
    $scope.open2 = function () {
      $scope.popup2.opened = true;
    };

    $scope.setDate = function (year, month, day) {
      $scope.dt = new Date(year, month, day);
    };

    $scope.formats = ['dd-MM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[0];
    $scope.altInputFormats = ['M!/d!/yyyy'];

    $scope.popup1 = {
      opened: false,
    };
    $scope.popup2 = {
      opened: false,
    };
    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0, 0, 0, 0);

        for (var i = 0; i < $scope.events.length; i++) {
          var currentDay = new Date($scope.events[i].date).setHours(0, 0, 0, 0);

          if (dayToCheck === currentDay) {
            return $scope.events[i].status;
          }
        }
      }
      return '';
    }
    /* date picker */

    /* time picker */
    $scope.example1 = {
      value: new Date(1970, 0, 1, 14, 57, 0),
    };
    $scope.example2 = {
      value: new Date(1970, 0, 1, 14, 57, 0),
    };
    /* time picker */
  },
]);
